import React, { useState, useEffect } from "react";
import moment from "moment";
import AuthService from "../../Api_Module/Api_Services/AuthService";
import { alertErrorMessage, alertWarningMessage } from "../../UtilityComponent/CustomAlertMessage";
import { usePagination, useSortBy, useTable } from "react-table";
import LoaderHelper from "../../UtilityComponent/Loading/LoaderHelper";
import { useOutletContext } from "react-router-dom";

const ActivitylogPage = () => {

  const [activity, setActivity] = useState([]);
  const [skipCount, setSkipCount] = useState(0);
  const [setActiveTab] = useOutletContext()
  let limitCount = 10
  useEffect(() => {
    let URL = window.location.href?.split('/');
    let route = URL.pop();
    setActiveTab(route)
    activityLogs(0, 10);
  }, []);

  const activityLogs = async (skip, limit) => {
    LoaderHelper.loaderStatus(true)
    await AuthService.activityLogs(skip, limit).then(async result => {
      if (result?.success) {
        if (result?.data?.length > 0) {
          setSkipCount(skip);
          setActivity(result?.data);
          return;
        } else if (skip !== 0) {
          alertWarningMessage('No more data found')
        }
      } else {
        alertErrorMessage(result?.message);
      }
    });
  };


  // ******* React Table Implementation Trade History *********** //
  const data = React.useMemo(() => activity, [activity]);
  const columns = React.useMemo(() => [
    {
      Header: "Sr No.", accessor: "_id", disableSortBy: true, Cell: ({ row }) => {
        const { index } = row; return index + 1 + skipCount
      }
    },
    { Header: "Date/Time", accessor: "createdAt", disableSortBy: true, Cell: ({ row }) => { return moment(row?.values?.createdAt).format('MMMM Do YYYY, h:mm:ss a') } },
    { Header: "Page Visited", accessor: "page", disableSortBy: true, },
    { Header: "IP Address", accessor: "ipAddress", disableSortBy: true, },

  ], [skipCount]);

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } = useTable({ columns, data }, useSortBy, usePagination);
  return (
    <>
      <div className="tab-pane" id="ActivityPill" role="tabpanel" aria-labelledby="Activity-pill">
        {/* <div className="upload-formate mb-6 d-flex justify-content-center align-items-center">
          <div>
            <h3 className="mb-1 text-center">
              Activity Logs
            </h3>
            <p className="formate mb-0">
              Your  Activity Logs display for all Activity
            </p>
          </div>
        </div> */}


          <div class="card twofa_card">
            <div class="card-body">
              <div class="card-header">
                <h3>Activity Logs  </h3>
                <p class="mb-0 text-muted">Your  Activity Logs display for all Activity </p>
              </div>
              {/* <div class="card-body_inner mt-10 "></div> */}
            
        


          {activity.length === 0 ?
            <div className="favouriteData">
              <img src="/images/no-data.svg" className="img-fluid" width="96" height="96" alt="" />
              <br />
              <p className="mt-3 mb-4" > No Data Found. </p>
            </div>
            :
            <div className="row">
              <div className="col-md-12 m-auto">
                <div className="card-body_inner mt-10">
                  <div className="activity-wrapper">
                    <div className="custom-history">
                      <table className="table a"  {...getTableProps()}>
                        <thead  >
                          {headerGroups.map((headerGroup, index) => (
                            <tr key={index} {...headerGroup.getHeaderGroupProps()} >
                              {headerGroup.headers.map((column, index) => (
                                <th key={index} {...column.getHeaderProps(column.getSortByToggleProps())}>
                                  {column.render("Header")}
                                  {(column.Header === 'Price' || column.Header === 'Fee' || column.Header === 'Executed') && <i className="ri-arrow-up-down-fill ms-1" style={{ color: column.isSorted ? (column.isSortedDesc ? '#ff00009c' : 'green') : '' }}></i>}
                                </th>
                              ))}
                            </tr>
                          ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                          {page.map((row, index) => {
                            prepareRow(row);
                            return (
                              <tr key={index} {...row.getRowProps()}>
                                {row.cells.map((cell, index) => (
                                  <td key={index} {...cell.getCellProps()}>{cell.render("Cell")} </td>
                                ))}
                              </tr>
                            );
                          })}
                          {activity.length === 0 && <tr rowSpan="5">
                            <td colSpan="12">
                              <p className="text-center" style={{ textAlign: "center" }}>
                                No data Available
                              </p>
                            </td>
                          </tr>}
                        </tbody>

                      </table>
                      {activity.length !== 0 &&
                        <div className="container pt-3   table_control" >
                          <div className=" row align-items-center justify-content-end " >
                            <div className="btn-group btn-group-mini justify-content-end " role="group" aria-label="Basic radio toggle button group">
                              <input type="button" className="btn-check" name="btnradio" id="btnradio250" autoComplete="off" disabled={skipCount <= 1} />
                              <label className="btn " htmlFor="btnradio250" onClick={() => { activityLogs(skipCount - 10, limitCount) }}>Previous</label>

                              <input type="button" className="btn-check" name="btnradio" id="btnradio375" autoComplete="off" disabled={activity.length < limitCount} />
                              <label className="btn " htmlFor="btnradio375" onClick={() => { activityLogs(skipCount + 10, limitCount) }}>Next</label>
                            </div>
                          </div>
                        </div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
      </div>
      </div>

      </div>
    </>
  );
}

export default ActivitylogPage;