import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LoaderHelper from "../../UtilityComponent/Loading/LoaderHelper";
import AuthService from "../../Api_Module/Api_Services/AuthService";
import { alertErrorMessage, alertSuccessMessage, alertWarningMessage } from "../../UtilityComponent/CustomAlertMessage";
import { $ } from "react-jquery-plugin";
import DataTableBase from "../../UtilityComponent/DataTable";


const Staking = () => {
    const [stakingCurr, setStakingCurr] = useState([]);
    const [pendingStacking, setPendingStacking] = useState([]);
    const [breakedStaking, setBreakedStaking] = useState([]);
    const [stakingModalDetails, setStakingModalDetails] = useState({});
    const [stakingDays, setStakingDays] = useState("");
    const [stakingAmount, setStakingAmount] = useState("");
    const [interstEndDate, setInterstEndDate] = useState("");
    const [brakeStakingData, setBrakeStakingData] = useState({});


    const getAvailableStaking = async (orderId) => {
        try {
            LoaderHelper.loaderStatus(true);
            const result = await AuthService.availabe_staking(orderId)
            LoaderHelper.loaderStatus(false);
            if (result?.success) {
                setStakingCurr(result?.data)
            }
        } catch (error) {
            LoaderHelper.loaderStatus(false);
            alertErrorMessage(error?.message)
        }

    };

    const getBreakedStaking = async () => {
        try {
            LoaderHelper.loaderStatus(true);
            const result = await AuthService.staking_history()
            LoaderHelper.loaderStatus(false);
            if (result?.success) {
                const updatedData = result?.data?.map((data) => {
                    let rewardGained = (((+data?.currency_Amount / 100) * +data?.month_percentage) / 30) * +data?.running_days
                    rewardGained = parseFloat(rewardGained?.toFixed(4))
                    let rewardDedcuted = (rewardGained / 100) * +data?.breaking_percentages
                    rewardDedcuted = parseFloat(rewardDedcuted?.toFixed(4))
                    let disbursalAmount = data?.currency_Amount + rewardGained - rewardDedcuted
                    disbursalAmount = parseFloat(disbursalAmount?.toFixed(4))
                    return { ...data, rewardGained, rewardDedcuted, disbursalAmount }
                })
                setBreakedStaking(updatedData)
            }
        } catch (error) {
            LoaderHelper.loaderStatus(false);
            alertErrorMessage(error?.message)
        }

    };

    const getPendingStakingHistory = async () => {
        try {
            LoaderHelper.loaderStatus(true);
            const result = await AuthService.pending_staking_history()
            LoaderHelper.loaderStatus(false);
            if (result?.success) {
                setPendingStacking(result?.data)
            }
        } catch (error) {
            LoaderHelper.loaderStatus(false);
            alertErrorMessage(error?.message)
        }

    };

    const placeStaking = async (currency_id, stakingAmount, stakingDays) => {
        if (stakingAmount > stakingModalDetails?.max_amount) {
            alertWarningMessage(`Amount must not be greater than ${stakingModalDetails?.max_amount}`);
            setStakingAmount("");
            return
        } else if (stakingAmount < stakingModalDetails?.min_amount) {
            alertWarningMessage(`Amount must be greater than ${stakingModalDetails?.min_amount}`);
            return
        }
        try {
            LoaderHelper.loaderStatus(true);
            const result = await AuthService.place_staking(currency_id, stakingAmount, stakingDays)
            LoaderHelper.loaderStatus(false);
            if (result?.success) {
                alertSuccessMessage("Staking Placed Successfully!!")
                $("#Stack_modal").modal("hide");
                $("#Stack_complete_modal").modal("show");

            }
        } catch (error) {
            LoaderHelper.loaderStatus(false);
            alertErrorMessage(error?.message)
        }

    };

    const breakStaking = async (transaction_id) => {
        try {
            LoaderHelper.loaderStatus(true);
            const result = await AuthService.break_staking(transaction_id)
            LoaderHelper.loaderStatus(false);
            if (result?.success) {
                alertSuccessMessage("Staking breaked!!")
                $("#Stack_brake_modal").modal("hide");
                getBreakedStaking()

            }
        } catch (error) {
            LoaderHelper.loaderStatus(false);
            alertErrorMessage(error?.message)
        }

    };

    const handleStakingModal = (data) => {
        setStakingAmount("")
        setStakingModalDetails(data)
        getInteretEndDate(data?.available_days[0])
        setStakingDays(data?.available_days[0])
        $("#Stack_modal").modal("show");
    };

    const getInteretEndDate = (days) => {
        let today = new Date();
        today.setDate(today.getDate() + +days);
        let formattedDate = today.toISOString().split('T')[0];
        setInterstEndDate(formattedDate)
    }

    const fundsAction = (row) => {
        return (
            <div className="d-flex align-items-center justify-content-center">
                <a href="#/" className="tb_btn badge badge-warning m-1" onClick={() => handleStakingModal(row)}>Stake</a>
            </div>
        )
    };

    const breakingStakeButton = (row) => {
        return (
            <div className="d-flex align-items-center justify-content-center">
                <a href="#/" className="tb_btn badge badge-warning m-1" onClick={() => brakeStakingModal({ ...row, id: row?._id, breakingPerc: row?.breaking_percentages })}>Break Staking</a>
            </div>
        )
    };

    const stakingDurationFormat = (row) => {
        return (
            row?.selected_day < 30 ? `${row?.selected_day} Days` : row?.selected_day >= 30 && row?.selected_day < 365 ? `${parseFloat((row?.selected_day / 30)?.toFixed(2))} Month` : `${parseFloat((row?.selected_day / 365)?.toFixed(2))} Year`)
    }

    const stakingfundsColumn = [
        { name: "Sr No.", compact: 1, selector: (row, index) => index + 1 },
        { name: "Assets", selector: row => row.short_name, },
        { name: "Balance", selector: row => row.balance, },
        { name: "Action", wrap: true, grow: 1, selector: fundsAction }
    ];

    const pendongStakingfundsColumn = [
        { name: <>Sr No.</>, compact: 1, selector: (row, index) => index + 1 },
        { name: <>Assets</>, selector: row => row.short_name, },
        { name: <>Staking Amount</>, selector: row => row.currency_Amount, },
        { name: <>Running Days</>, selector: row => row.running_days, },
        { name: <>Running Reward Price </>, selector: row => row.running_reward_price, },
        { name: <>Staking Reward Rate</>, selector: row => `${row.month_percentage}%/Month`, },
        { name: <>Staking Duration</>, selector: stakingDurationFormat },
        { name: <>Status</>, selector: row => row.status, },
        { name: <>Action</>, wrap: true, grow: 1, selector: breakingStakeButton }
    ];

    const breakedStakingColumn = [
        { name: <>Sr No.</>, compact: 1, selector: (row, index) => index + 1 },
        { name: <>Assets</>, selector: row => row.short_name, },
        { name: <>Staking Amount</>, selector: row => row.currency_Amount, },
        { name: <>Staking Days</>, selector: row => row.running_days, },
        { name: <>Reward Price </>, selector: row => row.rewardGained, },
        { name: <>Breaking Charge </>, selector: row => row.rewardDedcuted, },
        { name: <>Disbursal Amount </>, selector: row => row.disbursalAmount, },
        { name: <>Staking Reward Rate</>, selector: row => `${row.month_percentage}%/Month`, },
        { name: <>Staking Duration</>, selector: stakingDurationFormat },
        { name: <>Status</>, selector: row => row.status, },
    ];

    const handleStakingInput = (e, maxAmount) => {
        const value = e.target.value;
        setStakingAmount(value);
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (stakingAmount > stakingModalDetails?.max_amount) {
                alertWarningMessage(`Amount must not be greater than ${stakingModalDetails?.max_amount}`);
                setStakingAmount("");
            }
        }, 1000);
        return () => clearTimeout(timeout)
    }, [stakingAmount]);

    const brakeStakingModal = (data) => {
        $("#Stack_brake_modal").modal("show");
        let rewardGained = (((+data?.currency_Amount / 100) * +data?.month_percentage) / 30) * +data?.running_days
        rewardGained = parseFloat(rewardGained?.toFixed(4))
        let rewardDedcuted = (rewardGained / 100) * +data?.breaking_percentages
        rewardDedcuted = parseFloat(rewardDedcuted?.toFixed(4))
        let disbursalAmount = data?.currency_Amount + rewardGained - rewardDedcuted
        disbursalAmount = parseFloat(disbursalAmount?.toFixed(4))
        setBrakeStakingData({ ...data, rewardGained, rewardDedcuted, disbursalAmount })
    };

    useEffect(() => {
        getAvailableStaking();
    }, []);

    return (
        <>
            <section className="inner-page-banner">
                <div className="container">
                    <div className="inner text-center">
                        <h1 className="title">Staking</h1>
                        <nav className="mt-4">
                            <ol className="breadcrumb justify-content-center">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Staking</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </section>
            <section >
                <div className="container">
                    <div className="d-flex-between mb-5 custom_dlflex">
                        <ul className="nav custom-tabs overflowx_scroll funds_tab">
                            <li><a className=" active" data-bs-toggle="tab" href="#Staking" onClick={getAvailableStaking}>Staking</a></li>
                            <li><a data-bs-toggle="tab" href="#Stakinghistory" onClick={getPendingStakingHistory}>Staking History</a></li>
                            <li><a data-bs-toggle="tab" href="#BreakStakinghistory" onClick={getBreakedStaking}>Breaked/Completed Staking</a></li>
                        </ul>
                    </div>
                    <div className="tab-content custom-tab-content p-0">
                        <div className="tab-pane fade show container active form-field-wrapper table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded" id="Staking">
                            <div className="table-responsive">
                                <DataTableBase columns={stakingfundsColumn} data={stakingCurr} />
                            </div>
                        </div>

                        {/* *****Staking history******** */}
                        <div className="tab-pane container fade form-field-wrapper table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded" id="Stakinghistory">
                            <div className="table-responsive">
                                <DataTableBase className="rdt_Table rdt_TableHeader rdt_TableHead rdt_TableHeadRow rdt_TableRow rdt_TableCol" columns={pendongStakingfundsColumn} data={pendingStacking} />
                            </div>
                        </div>

                        {/* *****Breaked/Completed history******** */}
                        <div className="tab-pane container fade form-field-wrapper table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded" id="BreakStakinghistory">
                            <div className="table-responsive">
                                <DataTableBase className="rdt_Table rdt_TableHeader rdt_TableHead rdt_TableHeadRow rdt_TableRow rdt_TableCol" columns={breakedStakingColumn} data={breakedStaking} />
                            </div>
                        </div>

                    </div>
                </div>
            </section >

            {/* Stacking modal */}
            <div className="modal fade" id="Stack_modal" tabIndex="-1" aria-labelledby="Stack_modalLaebl" aria-hidden="true" onClick={(e) => e.stopPropagation()}>
                <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content">
                        <div className="modal-header flex-column px-8">
                            <h3 className="modal-title" id="placeBitLaebl">
                                Stake
                            </h3>
                            <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close" >
                                <i className="ri-close-fill"></i>
                            </button>
                        </div>
                        <div className="modal-body px-5 py-5">
                            <div className="step_1 " >
                                <div className="st_head" >
                                    <div className="st_top" >
                                        <div>
                                            <label>Balance</label>
                                            <h5 className="mb-0" >{`${parseFloat(stakingModalDetails?.balance?.toFixed(4))} ${stakingModalDetails?.short_name}`}</h5>
                                        </div>
                                    </div>
                                    <div className="gc_hr" ></div>
                                    <div className="st_top" >
                                        <div className="text-start" >
                                            <label>Interest Amount</label>
                                            <h5 className="mb-0" >{stakingModalDetails?.month_percentage}%/Month</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="st_body">
                                    <div className="form-group mb-1">
                                        <label for="phase">Amount </label>
                                        <input type="number" name="amount_val" placeholder="Enter Amount" value={stakingAmount} onWheel={(e) => e.target.blur()} onChange={(e) => handleStakingInput(e, stakingModalDetails?.max_amount)} />
                                        <span className="txt_badge d-flex justify-content-between" >
                                            <small>Min : {stakingModalDetails?.min_amount}</small>
                                            <small>Max : {stakingModalDetails?.max_amount}</small>
                                        </span>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label for="phase"> Staking Days </label>
                                        <select name="Days" value={stakingDays} onChange={(e) => { setStakingDays(e.target.value); getInteretEndDate(e.target.value) }} >
                                            <optgroup >
                                                {stakingModalDetails?.available_days?.map((data, index) => {
                                                    return <option value={data} key={index} >
                                                        {data < 30 ? `${data} Days` : data >= 30 && data < 365 ? `${parseFloat((data / 30)?.toFixed(2))} Month` : `${parseFloat((data / 365)?.toFixed(2))} Year`}
                                                    </option>
                                                })}
                                            </optgroup>
                                        </select>
                                    </div>
                                    <div className="form-group mt-5">
                                        <button type="button" className="btn btn-gradient btn-small w-100 justify-content-center"
                                            disabled={(stakingAmount < stakingModalDetails?.min_amount || stakingAmount > stakingModalDetails?.max_amount)} onClick={() => placeStaking(stakingModalDetails?.currency_id, stakingAmount, stakingDays)}>
                                            <span>Stake</span>
                                        </button>
                                    </div>
                                    <div className="st_list" >
                                        <div className="st_row" >
                                            <em>Minimum staking amount</em>
                                            <span>{`${stakingModalDetails?.min_amount} ${stakingModalDetails?.short_name}`}</span>
                                        </div>
                                        <div className="st_row" >
                                            <em>Maximum staking amount</em>
                                            <span>{`${stakingModalDetails?.max_amount} ${stakingModalDetails?.short_name}`} </span>
                                        </div>
                                        <div className="st_row" >
                                            <em>You will receive</em>
                                            <span> {parseFloat((+stakingAmount + ((+stakingAmount / 100) * ((stakingDays / 30) * stakingModalDetails?.month_percentage)))?.toFixed(3))} </span>
                                        </div>
                                        <div className="st_row" >
                                            <em>Transaction Cost</em>
                                            <span>NA </span>
                                        </div>
                                    </div>
                                    <div className="st_list" >
                                        <h4 className="mb-1"> Summary </h4>
                                        <div className="st_row" >
                                            <em>Total estimate reward</em>
                                            <span>{parseFloat(((stakingAmount / 100) * ((stakingDays / 30) * stakingModalDetails?.month_percentage))?.toFixed(3))} {stakingModalDetails?.short_name}</span>
                                        </div>
                                        <div className="st_row" >
                                            <em>Reward Percentage</em>
                                            <span> {parseFloat(((stakingDays / 30) * stakingModalDetails?.month_percentage)?.toFixed(3))}%</span>
                                        </div>
                                        <div className="st_row" >
                                            <em>Interest end date</em>
                                            <span>{interstEndDate}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            {/* Stacking Complete modal */}
            <div className="modal fade" id="Stack_complete_modal" tabIndex="-1" aria-labelledby="Stack_complete_modalLaebl" aria-hidden="true" onClick={(e) => e.stopPropagation()}>
                <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content">
                        <div className="modal-header flex-column px-8">
                            <h3 className="modal-title" id="placeBitLaebl">
                                Staking Successfull
                            </h3>
                            <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close" >
                                <i className="ri-close-fill"></i>
                            </button>
                        </div>
                        <div className="modal-body px-5 py-5">
                            <div className="step_2 " >
                                <div className="modal_alert" >
                                    <img src="https://cdn3d.iconscout.com/3d/premium/thumb/check-4112732-3408773@0.png?f=webp" width="150" className="img-fluid" alt="" />
                                    <h4 className="">  Successful   </h4>
                                    <h6>
                                        You have Successfully Staked  {stakingAmount}  {stakingModalDetails?.short_name}
                                    </h6>
                                    <div className="form-group mt-5">
                                        <button type="button" className="btn btn-gradient btn-small w-100 justify-content-center" data-bs-dismiss="modal" aria-label="Close" >
                                            <span>Done</span>
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div >

            {/* Stacking Brake */}
            <div className="modal fade" id="Stack_brake_modal" tabIndex="-1" aria-labelledby="Stack_brake_modalLaebl" aria-hidden="true" onClick={(e) => e.stopPropagation()}>
                <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content">
                        <div className="modal-header flex-column px-8">
                            <h3 className="modal-title" id="placeBitLaebl">
                                Do you want to break staking?
                            </h3>
                            <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close" >
                                <i className="ri-close-fill"></i>
                            </button>
                        </div>
                        <div className="modal-body px-5 pb-8">
                            <div className="st_list mb-5" >
                                <div className="st_row" >
                                    <em>Breaking Percenatge (from reward) :</em>
                                    <span>{brakeStakingData?.breakingPerc}%</span>
                                </div>
                                <div className="st_row" >
                                    <em>Reward Gained :</em>
                                    <span>{brakeStakingData?.rewardGained} {brakeStakingData?.short_name}</span>
                                </div>
                                <div className="st_row" >
                                    <em>Breaking Deduction :</em>
                                    <span>{brakeStakingData?.rewardDedcuted} {brakeStakingData?.short_name}</span>
                                </div>
                                <div className="st_row" >
                                    <em>You will get:</em>
                                    <span>{brakeStakingData?.disbursalAmount}</span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between gap-5 mt-3">
                                <button type="button" className="btn btn-gradient btn-large w-100 justify-content-center" onClick={() => { breakStaking(brakeStakingData?.id) }} >
                                    <span>Break</span>
                                </button>
                                <button type="button" className="btn btn-dark btn-large w-100 justify-content-center" data-bs-dismiss="modal" aria-label="Close" >
                                    <span>Cancel</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};

export default Staking;
