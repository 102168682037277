import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const Support = () => {
  const [inputData, setinputData] = useState({ name: '', email: '', subject: '', detail: '' });
  const [attachments, setAttachments] = useState();
  const location = useLocation();
  const isMobSupport = location?.pathname?.includes('/mobSupport');

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setinputData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  return (
    <>
      <section className="inner-page-banner">
        <div className="container">
          <div className="inner text-center">
            <h1 className="title"> Help & Support </h1>
            {!isMobSupport &&
              <nav className="mt-4">
                <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Support
                  </li>
                </ol>
              </nav>}
          </div>
        </div>
      </section>
      <section className="pb-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 m-auto">
              <div className="create-item-wrapper plicy_sec">

                <form className="" >
                  <div className="row" >

                    <div className="col-lg-6" >
                      <div className="form-group field-box mb-3 mb-md-4" >
                        <label className="form-label">Enter Name</label>
                        <input type="text" name="name" onChange={(e) => handleInputChange(e)} />
                      </div>
                    </div>

                    <div className="col-lg-6" >
                      <div className="form-group field-box mb-3 mb-md-4" >
                        <label className="form-label">Email Address</label>
                        <input type="text" name="email" onChange={(e) => handleInputChange(e)} />
                      </div>
                    </div>
                    <div className="col-lg-12" >
                      <div className="form-group field-box mb-3 mb-md-4" >
                        <label className="form-label">Enter Subject </label>
                        <input type="text" name="subject" onChange={(e) => handleInputChange(e)} />
                      </div>
                    </div>
                    <div className="col-lg-12" >
                      <div className="form-group field-box mb-3 mb-md-4" >
                        <label className="form-label">Enter Details </label>
                        <textarea rows="5" name="detail" onChange={(e) => handleInputChange(e)}></textarea>
                      </div>
                    </div>
                    <div className="col-lg-12" >
                      <div className="form-group field-box mb-3 mb-md-4" >
                        <label className="form-label">Attachments </label>
                        <input type="file" onChange={(e) => setAttachments(e.target.files[0])} />
                      </div>
                    </div>
                    <div className="col-lg-12  mb-4 mb-md-5" >
                      <p className="mb-0" > <small>Please provide your registered email address and a brief message. A customer support representative will assist you shortly!</small></p>
                    </div>

                    <div className="col-lg-12" >
                      <button className="btn btn-gradient w-100  text-center btn-xl d-block" type="button"> <span>Submit</span> </button>
                    </div>

                  </div>
                </form>

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Support;
