import React, { useEffect, createContext, useState, useRef } from 'react';
import AuthService from '../Api_Module/Api_Services/AuthService';


export const ProfileContext = createContext();

export function ProfileProvider(props) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [mobile, setMobile] = useState('');
  const [userImage, setUserImage] = useState();
  const [authType, setAuthType] = useState();
  const [kycStatus, setKycStatus] = useState();
  const [currency, setCurrency] = useState();
  const [buySellSkip, setbuySellSkip] = useState(false);
  const [tradeTheme, setTradeTheme] = useState(true);
  const [UIN, setUIN] = useState('');
  const [countryCode, setCountryCode] = useState("91:India");
  const [checkSignId, setcheckSignId] = useState({ email: false, mobile: false });
  let token = sessionStorage.getItem('FIP-Invest_AUTH_TOKEN');

  useEffect(() => {
    token && handleUserDetials()
  }, []);

  // ********* Update Theme ********** //
  const functCheckRef = useRef(true);
  useEffect(() => {
    if (functCheckRef.current) {
      const body = document.getElementById("body");
      let Theme = localStorage.getItem('theme-color');
      if (Theme === 'theme-light') {
        body.classList.add('theme-light');
        body.classList.remove("theme-dark");
      } else {
        localStorage.setItem('theme-color', "theme-dark");
        body.classList.add('theme-dark');
        body.classList.remove("theme-light");
      }
      functCheckRef.current = false;
    }
  }, []);

  // ********* User Profile Details ********** //
  const handleUserDetials = async () => {
    await AuthService.getDetails().then(async (result) => {
      if (result?.success) {
        // console.log(result?.data)
        setcheckSignId({
          email: result?.data?.emailId ? true : false,
          mobile: result?.data?.mobileNumber ? true : false,
        })
        setEmail(result?.data?.emailId);
        setMobile(result?.data?.mobileNumber);
        setFirstName(result?.data?.firstName);
        setLastName(result?.data?.lastName);
        setUserImage(result?.data?.profilepicture);
        setAuthType(result?.data?.["2fa"]);
        setKycStatus(result?.data?.kycVerified);
        setCode(result?.data?.code);
        setCurrency(result?.data?.currency_prefrence);
        setbuySellSkip(result?.data?.buy_sell_model_skip);
        setUIN(result?.data?.uin);
        setCountryCode(result?.data?.country_code ? result?.data?.country_code : "91:India");
      }
    });
  };


  // *********Button Scroll Upside ********** //
  useEffect(() => {
    const btnScrollToTop = document.querySelector("#btnScrollToTop");
    if (btnScrollToTop) {
      btnScrollToTop.addEventListener("click", e => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
      });
      window.addEventListener('scroll', e => {
        window.scrollY > 100 ? btnScrollToTop.style.display = "block" : btnScrollToTop.style.display = "none";
      });
    }
  }, []);
  return (
    <>
      <button id='btnScrollToTop' type='button'><i className="ri-arrow-up-line"></i></button>
      <ProfileContext.Provider value={{ handleUserDetials, firstName, setFirstName, email, setEmail, lastName, setLastName, setMobile, mobile, userImage, setUserImage, authType, kycStatus, currency, setCurrency, buySellSkip, tradeTheme, setTradeTheme, checkSignId, UIN, countryCode, setCountryCode, code, setCode }}>{props.children}</ProfileContext.Provider>
    </>
  );
}


