import React, { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import { Link, useNavigate } from "react-router-dom";
import LoaderHelper from "../../UtilityComponent/Loading/LoaderHelper";
import { alertErrorMessage, alertSuccessMessage, alertWarningMessage } from "../../UtilityComponent/CustomAlertMessage";
import AuthService from "../../Api_Module/Api_Services/AuthService";
import DefaultInput from "../../UtilityComponent/DefaultInput";
import { matchPassword, notEqualsZero, validPassword, validPasswordLength, validateEmail } from "../../UtilityComponent/Validation";
import { CountryDetails } from "../../UtilityComponent/CountryList";
import { UserTypes } from "./UserTypeList";

const SignupPage = () => {

  const navigate = useNavigate();
  const ref = window.location.href.split("=")[1];
  const [signId, setSignId] = useState("");
  const [password, setPassword] = useState("");
  const [cPassword, setCpassword] = useState("");
  const [countryCode, setCountryCode] = useState("91:India");
  const [invitation, setInvitation] = useState(ref ? ref : '');
  const [otp, setOtp] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userType, setUserType] = useState("ambassador");
  const [userTypeEmail, setUserTypeEmail] = useState("ambassador");
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedCondition, setIsCheckedCondition] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [reEnterPasswordType, setReEnterPasswordType] = useState("password");
  const [disableBtn, setDisbaleBtn] = useState(false);
  const [validations, setValidations] = useState(false);
  const [numberValidation, setNumberValidation] = useState(false);
  const [specialValidation, setSpecialValidation] = useState(false);
  const [minLength, setMinLength] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const handleRegister = async (signId, password, cPassword, otp, invitation, countryCode, firstName, lastName, userType) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.register(signId, password, cPassword, otp, invitation, countryCode, firstName, lastName, userType).then(async (result) => {
      if (result?.success) {
        alertSuccessMessage('Registration Successfull!!')
        sessionStorage.setItem("FIP-Invest_AUTH_TOKEN", result?.data?.token);
        navigate("/trade/exchange");
        window.location.reload()
      }
    });
  };
  useEffect(() => {
    password && /(?=.*?[0-9])/.test(password)
      ? setNumberValidation(true)
      : setNumberValidation(false);
  }, [password]);

  useEffect(() => {
    password && /(?=.*?[#?!@$%^&*-])/.test(password)
      ? setSpecialValidation(true)
      : setSpecialValidation(false);
  }, [password]);

  useEffect(() => {
    password && /.{6,}/.test(password)
      ? setMinLength(true)
      : setMinLength(false);
  }, [password]);

  const handleGetOtp = async (signId, type) => {
    if (type === 'phone') {
      const [code, label] = countryCode.split(':');
      let filteredCountry = CountryDetails?.filter((data) => data?.phone === code && data?.label === label)?.map((item) => item?.phoneLength);
      if (signId?.length !== filteredCountry[0]) {
        alertWarningMessage(`Please enter valid phone number for ${label}`)
        return
      }
    };
    LoaderHelper.loaderStatus(true);
    await AuthService.getOtp(signId, 'registration').then(async (result) => {
      LoaderHelper.loaderStatus(false);
      if (result?.success) {
        alertSuccessMessage(result?.message);
        setDisbaleBtn(true);
      }
    });
  };

  const handleResetInput = () => {
    setDisbaleBtn(false)
    setSignId("");
    setPassword("");
    setCpassword("");
    setCountryCode("91:India");
    setOtp("");
    setFirstName("");
    setLastName("");
    setUserType("")
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      return setPasswordType("text");
    }
    return setPasswordType("password");
  };

  const toggleConfirmPassword = () => {
    if (reEnterPasswordType === "password") {
      return setReEnterPasswordType("text");
    }
    return setReEnterPasswordType("password");
  };




  return (
    <>
      <section className="inner-page-banner pb-0 login_page  ">


        {/* <section className=""> */}
        <div className="container">
          <div className="row align-items-cente  justify-content-center">
            {/* <div className="col-xl-7 col-lg-12 ">
              <div className="poster_slider poster_slider_signup">
                <h2 className="mb-5  ">
                  Welcome to <br />
                  <span className="text-gradient">fipnext</span>
                </h2>
                <Swiper className="market_slider  pb-11" spaceBetween={10} loop={true} autoplay={{
                  delay: 2000
                }}
                  pagination={{
                    dynamicBullets: true,
                  }}
                  modules={[Autoplay, Pagination, Navigation]}
                  breakpoints={{
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    1024: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                  }}>
                  <SwiperSlide>
                    <a href="#/" className="main_poster"><img src="/images/poster_1.png" alt="" /></a>
                  </SwiperSlide>
                  <SwiperSlide>
                    <a href="#/" className="main_poster"><img src="/images/poster_2.png" alt="" /></a>
                  </SwiperSlide>
                  <SwiperSlide>
                    <a href="#/" className="main_poster"><img src="/images/poster_1.png" alt="" /></a>
                  </SwiperSlide>
                  <SwiperSlide>
                    <a href="#/" className="main_poster"><img src="/images/poster_2.png" alt="" /></a>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div> */}
            <div className="col-lg-6 col-12 mx-auto">
              <div class="page_title">
                <h2 class="d-block text-center mb-5">Sign Up to  <strong class="text-gradient">fipnext</strong></h2>
              </div>
              <div className="custom-form ticket-form mb-5 mb-lg-0">

                <div className="card-header login-header" >
                  <ul className="nav nav-pills mb-3 login-pills">
                    <li>
                      <a className="active nav-link" data-bs-toggle="tab" href="#login_Mobile" onClick={handleResetInput}>
                        Mobile
                      </a>
                    </li>
                    <li>
                      <a data-bs-toggle="tab" href="#login_email" className="nav-link" onClick={handleResetInput}>
                        Email
                      </a>
                    </li>
                  </ul>
                </div>


                {/* <div
                  className="signup-content signin-content"
                  onClick={(e) => { setValidations(false) }} > */}
                {/* <div className="sign-in_tab" id="qwerty"> */}
                {/* <h3 className="mb-2">Sign Up to fipnext</h3> */}

                <div className="tab-content pt-4">
                  <div className="tab-pane show  px-0 container active" id="login_Mobile">
                    <div>
                      <div className="row">
                        <div className="col-6">
                          <div className="row">
                            <div className="col-12">
                              <div className="fleld-box">
                                <label htmlFor="Code" className="form-label">
                                  First Name
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="field-box ">
                                <input id="firstName" name="firstName" className="form-control" placeholder="Enter First Name"
                                  onChange={(e) => setFirstName(e.target.value)} />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-12">
                              <div className="fleld-box">
                                <label htmlFor="Code" className="form-label">
                                  Last Name
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="field-box ">
                                <input id="lastName" name="lastName" className="form-control" placeholder="Enter Last Name"
                                  onChange={(e) => setLastName(e.target.value)} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <div className="fleld-box">
                            <label htmlFor="Code" className="form-label">
                              Mobile
                              <span style={{ color: "red" }}>*</span>
                            </label>
                          </div>
                        </div>
                        <div className="col-12 ">
                          <div className="field-box ">
                            <select name="countryCode" className="form-control form-select" value={countryCode} onChange={(e) => setCountryCode(e.target.value)}>
                              <optgroup >
                                {CountryDetails?.map(value => {
                                  return <option value={`${value?.phone}:${value?.label}`} key={`${value?.phone}:${value?.label}`}>
                                    {`${value?.label}  (+${value?.phone})`}
                                  </option>
                                })}
                              </optgroup>
                            </select>
                          </div>
                        </div>
                        <div className="col-12  ">
                          <div className="field-box field-otp-box">
                            <input id="mobile" name="mobile" className="form-control" type="number" placeholder="Enter mobile number"
                              value={signId} onChange={(e) => setSignId(e.target.value)} onWheel={(e) => e.target.blur()} />
                            <button type="btn" className="btn btn-sm btn-gradient" onClick={() => handleGetOtp(signId, 'phone')}>
                              <span>{disableBtn ? 'Resend OTP' : 'GET OTP '} </span>
                            </button>
                          </div>
                        </div>
                        <div className="col-md-12 ">
                          <div className="field-box">
                            <label htmlFor="emailotp" className="form-label">
                              Verification Code<span style={{ color: "red" }}>*</span>
                            </label>
                            <input id="emailotp" name="otp" className="form-control" type="number" placeholder="Enter verification code"
                              value={otp} onChange={(e) => setOtp(e.target.value)} onWheel={(e) => e.target.blur()} />
                          </div>
                        </div>
                        
                          <div className="col-12">
                            <div className="fleld-box">
                              <label htmlFor="Code" className="form-label">
                                User Type
                                <span style={{ color: "red" }}>*</span>
                              </label>
                            </div>
                          </div>
                          <div className="col-12 ">
                            <div className="field-box ">
                              <select name="userType" className="form-control form-select" value={userType} onChange={(e) => setUserType(e.target.value)}>
                                {UserTypes?.map(value => {
                                  return <option value={`${value?.value}`} key={`${value?.value}`}>
                                    {`${value?.label}`}
                                  </option>
                                })}
                              </select>
                            </div>
                          </div>
                        
                        <div className="col-md-12 ">
                          <div className="field-box ">
                            <label htmlFor="password" className="form-label">
                              Password<span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="field-otp-box">
                              <DefaultInput type={passwordType} className="form-control" name="password" value={password} placeholder="Enter password"
                                autoComplete="off"
                                errorstatus={(validPassword(password) !== undefined &&
                                  notEqualsZero(password) && validPasswordLength(password)) ? 'true' : 'false'}
                                errormessage={!validations ? validPassword(password) && validPasswordLength(password) : ""}
                                onChange={(e) => setPassword(e.target.value)}
                                onClick={(e) => e.stopPropagation()}
                                onFocus={(e) => { setValidations(true) }} />
                              <a className="show_password opt_btn btn-sm" onClick={togglePassword}>
                                {passwordType === "password" ? <i className="ri-eye-off-line" />
                                  : <i className="ri-eye-line" />}
                              </a>
                            </div>
                            <div
                              className={!validations ? "display-none" : "pass_conditions"}>
                              <div>
                                {validations &&
                                  (numberValidation ?
                                    <div className="d-flex align-items-center">
                                      <i className="ri-check-double-line text-success"></i>
                                      <span style={{ fontSize: "small" }}>
                                        At least 1 number
                                      </span>
                                    </div>
                                    :
                                    <div className="d-flex align-items-center">
                                      <i className="ri-close-fill text-danger"></i>
                                      <span style={{ fontSize: "small" }}>
                                        At least 1 number
                                      </span>
                                    </div>)}
                              </div>

                              <div>
                                {validations &&
                                  (specialValidation ?
                                    <div className="d-flex align-items-center">
                                      <i className="ri-check-double-line text-success"></i>
                                      <span style={{ fontSize: "small" }}>
                                        At least 1 special character
                                      </span>
                                    </div>
                                    :
                                    <div className="d-flex align-items-center">
                                      <i className="ri-close-fill text-danger"></i>
                                      <span style={{ fontSize: "small" }}>
                                        At least 1 special character
                                      </span>
                                    </div>
                                  )}
                              </div>

                              <div>
                                {validations &&
                                  (minLength ?
                                    <div className="d-flex align-items-center">
                                      <i className="ri-check-double-line text-success"></i>
                                      <span style={{ fontSize: "small" }}>
                                        Minimum 6 characters
                                      </span>
                                    </div>
                                    :
                                    <div className="d-flex align-items-center">
                                      <i className="ri-close-fill text-danger"></i>
                                      <span style={{ fontSize: "small" }}>
                                        Minimum 6 characters
                                      </span>
                                    </div>)}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12  ">
                            <div className="field-box">
                              <label htmlFor="cPassword" className="form-label">
                                Confirm Password<span style={{ color: "red" }}>*</span>
                              </label>
                              <div className="field-otp-box mb-4">
                                <DefaultInput type={reEnterPasswordType} className="form-control mb-0" name="cPassword" value={cPassword} placeholder="Enter confirm password" autoComplete="off" errorstatus={(notEqualsZero(cPassword) && matchPassword(password, cPassword)) ? 'true' : 'false'}
                                  errormessage={matchPassword(password, cPassword)} onChange={(e) => setCpassword(e.target.value)} />
                                <a className="show_password opt_btn btn-sm " onClick={toggleConfirmPassword} >
                                  {reEnterPasswordType === "password" ? <i className="ri-eye-off-line" />
                                    : <i className="ri-eye-line" />}
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12  ">
                            <div className="field-box">
                              <label htmlFor="password" className="form-label" >
                                Invitation code(Optional)
                              </label>

                              <input id="Invitation" name="mInvitation" className="form-control" type="text" placeholder="Enter invitation code" disabled={ref} value={ref ? ref : invitation}
                                onChange={(e) => setInvitation(e.target.value)} />
                            </div>
                          </div>
                          <div className="col-md-12 mb-4">
                            <div className="form-check  ">
                              <input className="form-check-input" type="checkbox" id="flexCheckDefault"
                                value={isChecked} onClick={() => setIsChecked((isChecked) => !isChecked)} />
                              I agree to accept
                              <Link to="/TermsOfUsePage" className="btn-link" target="_blank" >
                                {' '} Terms of Use <span style={{ color: "red" }}>*</span>
                              </Link>
                              {!isChecked && showMessage ? <div style={{ color: "red", fontWeight: "bold" }} >
                                Please accept Term of Use by clicking on checkbox
                              </div> : " "}
                            </div>
                          </div>
                          {/* <div className="col-md-12 mb-3 mt-3">
                                <div className="field-box"> */}
                          {/* <ReCAPTCHA theme='dark'
                                    ref={recaptchaRef}
                                    sitekey={siteKey}
                                    onChange={CapcthaVerify}
                                  /> */}
                          {/* </div>
                              </div> */}
                          <div className="col-md-12 ">
                            <div className="field-box">
                              <button
                                className="btn btn-gradient w-100 justify-content-center btn-medium"
                                type="button"
                                disabled={!signId || !password || !cPassword || !firstName || !lastName || !userType || !otp || password !== cPassword ||
                                  !(validPassword(password) === undefined ||
                                    !notEqualsZero(password)) || !notEqualsZero(signId) || !(validPassword(cPassword) === undefined || !notEqualsZero(cPassword))}
                                onClick={() => {
                                  isChecked ? handleRegister(signId, password, cPassword, otp, invitation, countryCode, firstName, lastName, userType)
                                    : setShowMessage((showMessage) => !showMessage)
                                }}>
                                <span>Sign Up</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane  px-0   container" id="login_email" >
                    <div>
                      <div className="row">
                        <div className="col-6">
                          <div className="row">
                            <div className="col-12">
                              <div className="fleld-box">
                                <label htmlFor="Code" className="form-label">
                                  First Name
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="field-box ">
                                <input id="firstName_" name="firstName" className="form-control" placeholder="Enter First Name"
                                  onChange={(e) => setFirstName(e.target.value)} />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-12">
                              <div className="fleld-box">
                                <label htmlFor="Code" className="form-label">
                                  Last Name
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="field-box ">
                                <input id="lastName_" name="lastName" className="form-control" placeholder="Enter Last Name"
                                  onChange={(e) => setLastName(e.target.value)} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12  ">
                          <div className="field-box">
                            <label htmlFor="email" className="form-label">
                              Email<span style={{ color: "red" }}>*</span>
                            </label>
                            <div className=" field-otp-box">
                              <input id="email" name="email" type="email" className="form-control" placeholder="Enter email" value={signId} onChange={(e) => setSignId(e.target.value)} />
                              <button type="btn" className="btn btn-sm btn-gradient"
                                onClick={() => {
                                  validateEmail(signId) !== undefined && notEqualsZero(signId) ? alertErrorMessage("Invalid emailId") : handleGetOtp(signId)
                                }} >
                                <span>{disableBtn ? 'Resend OTP' : 'GET OTP '}</span>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12  ">
                          <div className="field-box">
                            <label htmlFor="emailotp" className="form-label">
                              Verification Code<span style={{ color: "red" }}>*</span>
                            </label>
                            <input id="emailotp" name="otp" className="form-control" type="number" placeholder="Enter verification code" value={otp}
                              onChange={(e) => setOtp(e.target.value)} onWheel={(e) => e.target.blur()} />
                          </div>
                        </div>
                        
                          <div className="col-12">
                            <div className="fleld-box">
                              <label htmlFor="Code" className="form-label">
                                User Type
                                <span style={{ color: "red" }}>*</span>
                              </label>
                            </div>
                          </div>
                          <div className="col-12 ">
                            <div className="field-box ">
                              <select name="userTypeEmail" className="form-control form-select" value={userTypeEmail} onChange={(e) => setUserTypeEmail(e.target.value)}>

                                {UserTypes?.map(value => {
                                  return <option value={`${value?.value}`} key={`${value?.value}`}>
                                    {`${value?.label}`}
                                  </option>
                                })}

                              </select>
                            </div>
                          </div>
                        <div className="col-md-12  ">
                          <div className="field-box">
                            <label htmlFor="password" className="form-label">
                              Password<span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="field-otp-box">
                              <DefaultInput type={passwordType} name="password" className="form-control" value={password} placeholder="Enter password"
                                autoComplete="off" errorstatus={(validPassword(password) !== undefined && notEqualsZero(password) &&
                                  validPasswordLength(password)) ? 'true' : 'false'}
                                errormessage={!validations ? validPassword(password) && validPasswordLength(password) : ""}
                                onChange={(e) => setPassword(e.target.value)}
                                onClick={(e) => e.stopPropagation()}
                                onFocus={(e) => { setValidations(true) }} />
                              <a className="show_password opt_btn btn-sm" onClick={togglePassword}                                  >
                                {passwordType === "password" ? (
                                  <i className="ri-eye-off-line" />
                                ) : (
                                  <i className="ri-eye-line" />
                                )}
                              </a>
                            </div>
                            <div className={!validations ? "display-none" : "pass_conditions"} >
                              <div>
                                {validations &&
                                  (numberValidation ?
                                    <div className="d-flex align-items-center">
                                      <i className="ri-check-double-line text-success"></i>
                                      <span style={{ fontSize: "small" }}>
                                        At least 1 number
                                      </span>
                                    </div>
                                    :
                                    <div className="d-flex align-items-center">
                                      <i className="ri-close-fill text-danger"></i>
                                      <span style={{ fontSize: "small" }}>
                                        At least 1 number
                                      </span>
                                    </div>
                                  )}
                              </div>
                              <div>
                                {validations &&
                                  (specialValidation ?
                                    <div className="d-flex align-items-center">
                                      <i className="ri-check-double-line text-success"></i>
                                      <span style={{ fontSize: "small" }}>
                                        At least 1 special character
                                      </span>
                                    </div>
                                    :
                                    <div className="d-flex align-items-center">
                                      <i className="ri-close-fill text-danger"></i>
                                      <span style={{ fontSize: "small" }}>
                                        At least 1 special character
                                      </span>
                                    </div>
                                  )}
                              </div>

                              <div>
                                {validations && (minLength ?
                                  <div className="d-flex align-items-center">
                                    <i className="ri-check-double-line text-success"></i>
                                    <span style={{ fontSize: "small" }}>
                                      Minimum 6 characters
                                    </span>
                                  </div>
                                  :
                                  <div className="d-flex align-items-center">
                                    <i className="ri-close-fill text-danger"></i>
                                    <span style={{ fontSize: "small" }}>
                                      Minimum 6 characters
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12  ">
                          <div className="field-box">
                            <label htmlFor="cPassword" className="form-label">
                              Confirm Password<span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="field-otp-box mb-3">
                              <DefaultInput type={reEnterPasswordType} className="form-control mb-0" name="cPassword" value={cPassword} placeholder="Enter confirm password" autoComplete="off" errorstatus={(notEqualsZero(cPassword) &&
                                matchPassword(password, cPassword)) ? 'true' : 'false'} errormessage={matchPassword(password, cPassword)}
                                onChange={(e) => setCpassword(e.target.value)} />
                              <a className="show_password opt_btn btn-sm " onClick={toggleConfirmPassword}  >
                                {reEnterPasswordType === "password" ? <i className="ri-eye-off-line" /> : <i className="ri-eye-line" />}
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12  ">
                          <div className="field-box">
                            <label htmlFor="password" className="form-label">
                              Invitation code(Optional)
                            </label>
                            <input id="Invitations" name="Invitation" className="form-control" type="text" placeholder="Enter invitation code" disabled={ref} value={ref ? ref : invitation}
                              onChange={(e) => setInvitation(e.target.value)} />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-check mb-4">
                            <input className="form-check-input" type="checkbox" id="flexCheckDefault" value={isCheckedCondition}
                              onClick={() => setIsCheckedCondition((isCheckedCondition) => !isCheckedCondition)} />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                              I agree to accept{" "}
                              <Link to="/TermsOfUsePage" className="btn-link" target="_blank" >
                                Terms of Use
                                <span style={{ color: "red" }}>*</span>
                              </Link>
                              {!isCheckedCondition ? (
                                showMessage ? (
                                  <div style={{ color: "red", fontWeight: "bold", }}   >
                                    Please accept Term of Use by clicking on checkbox
                                  </div>
                                ) : " "
                              ) : " "
                              }
                            </label>
                          </div>
                        </div>
                        {/* <div className="col-md-12 mb-3 mt-3">
                              <div className="field-box"> */}
                        {/* <ReCAPTCHA theme='dark'
                                  ref={recaptchaRef2}
                                  sitekey={siteKey}
                                  onChange={CapcthaVerify}
                                /> */}
                        {/* </div>
                            </div> */}
                        <div className="col-md-12 ">
                          <div className="field-box">
                            <button
                              className="btn btn-gradient w-100 justify-content-center btn-medium"
                              type="button"
                              disabled={!signId || !password || !userTypeEmail || !firstName || !lastName || !cPassword || password !== cPassword || !(
                                validPassword(password) === undefined ||
                                !notEqualsZero(password)
                              ) || !(validateEmail(signId) === undefined || !notEqualsZero(signId)) ||
                                !(validPassword(cPassword) === undefined || !notEqualsZero(cPassword))}
                              onClick={() => { isCheckedCondition ? handleRegister(signId, password, cPassword, otp, invitation, "", firstName, lastName, userTypeEmail) : setShowMessage((showMessage) => !showMessage) }}>
                              <span>Sign Up</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center text-center mt-3">
                    <div className="col-lg-12">
                      Already have an account?{" "}
                      <Link to="/login" className="color-primary">
                        Sign In
                      </Link>
                    </div>
                  </div>
                </div>
                {/* </div> */}
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* </section> */}

      </section>
    </>
  );
};

export default SignupPage;
