import React, { useState } from "react";

const CoinApply = () => {
  const [formSteps, setFormSteps] = useState({ step1: true, step2: false, step3: false });

  const ChangeScreens = (screen) => {
    window.scrollTo({ top: 350, behavior: 'smooth' });
    if (screen === 'step1') {
      setFormSteps({
        step1: true,
        step2: false,
        step3: false
      });
    } else if (screen === 'step2') {
      setFormSteps({
        step1: false,
        step2: true,
        step3: false
      });
    }
    else if (screen === 'step3') {
      setFormSteps({
        step1: false,
        step2: false,
        step3: true
      });
    }
  };

  return (
    <>
      <div className="about_page clist coinappylform " >
        <section className="about_top" >
          <div className="container" >
            <div className="row g-md-5 align-items-center" >
              <div className="col-lg-12" >
                <h2 className="text-center" >
                  Listing a Coin on <span className="text-gradient" >fipnext</span>
                </h2>

              </div>
            </div>
          </div>
        </section>
      </div>
      <section className="pb-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 m-auto">

              {/* css_check_bar */}
              <div className="css_check_bar">
                <div className="sc-bdfBQB sc-kmATbt fOxqyX dzKkzw">
                  <div className={`check_bar ${formSteps.step1 || formSteps.step2 || formSteps.step3 ? 'acive' : ''}`} >
                    <i className="ri-check-fill "></i>
                    <h5> Step 1 </h5>
                  </div>
                  <div className={`check_bar ${formSteps.step2 || formSteps.step3 ? 'acive' : ''}`}>
                    <i className="ri-check-fill "></i>
                    <h5> Step 2</h5>
                  </div>
                  <div className={`check_bar ${formSteps.step3 ? 'acive' : ''}`}>
                    <i className="ri-check-fill "></i>
                    <h5> Step 3</h5>
                  </div>
                </div>
              </div>

              {/* css_check_bar end */}


              {/* step 1 */}
              <div className={`create-item-wrapper plicy_sec mb-3 ${formSteps.step1 ? '' : 'd-none'}`}>
                <form onSubmit={(e) => { e.preventDefault(); ChangeScreens('step2') }} >

                  <h6 className="text-danger mb-4 " >* Indicates required question</h6>
                  <div className="row" >

                    <div className="col-lg-12" >
                      <div className="form-group field-box mb-4 mb-md-4" >
                        <label className="form-label">Email* </label>
                        <input type="text" required />
                      </div>
                    </div>
                    <div className="col-lg-12" >
                      <div className="form-group field-box  mb-4 mb-md-4" >
                        <label className="form-label">Your Telegram ID* </label>
                        <input type="text" required />
                      </div>
                    </div>
                    <div className="col-lg-12" >
                      <div className="form-group field-box  mb-4 mb-md-4" >
                        <label className="form-label">Please entail the proof of concept behind the coin/token * </label>
                        <input type="text" required />
                      </div>
                    </div>
                    <div className="col-lg-12" >
                      <div className="form-group field-box  mb-4 mb-md-4" >
                        <label className="form-label">Please address the problem statement being solved.* </label>
                        <input type="text" required />
                      </div>
                    </div>
                    <div className="col-lg-12" >
                      <div className="form-group field-box  mb-4 mb-md-4" >
                        <label className="form-label">What is the vision for the project?*</label>
                        <input type="text" required />
                      </div>
                    </div>
                    <div className="col-lg-12" >
                      <div className="form-group field-box   mb-4 mb-md-4" >
                        <label className="form-label">Are there active users of the project?*</label>
                        <select required >
                          <option>  Yes </option>
                          <option>  No </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-12" >
                      <div className="form-group field-box  mb-4 mb-md-4" >
                        <label className="form-label">Are there active users of the project?*</label>
                        <select required>
                          <option>  Yes </option>
                          <option>  No </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-12" >
                      <div className="form-group field-box  mb-4 mb-md-4" >
                        <label className="form-label"> Is the underlying technology decentralized or is there a roadmap for the same?* </label>
                        <select required>
                          <option>  Yes </option>
                          <option>  No </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-12" >
                      <div className="form-group field-box  mb-4 mb-md-4" >
                        <label className="form-label"> Does the project benefit from using blockchain technology?* </label>
                        <select required>
                          <option>  Yes </option>
                          <option>  No </option>
                        </select>
                      </div>
                    </div>

                    <div className="row justify-content-center mt-md-4 mt-4" >
                      <div className="col-6 " >
                        <button className="btn btn-gradient w-100  text-center btn-xl d-block" type="submit"> <span>Next</span> </button>
                      </div>
                    </div>

                  </div>
                </form>
              </div>

              {/* step 2 */}
              <div className={`create-item-wrapper plicy_sec mb-3 ${formSteps.step2 ? '' : 'd-none'}`}>
                <form onSubmit={(e) => { e.preventDefault(); ChangeScreens('step3') }} >
                  <p>The name and photo associated with your Google account will be recorded when you upload files and submit this form. Only the email you enter is part of your response. </p>
                  <h6 className="text-danger mb-4 " >* Indicates required question</h6>
                  <div className="row" >
                    <strong className="d-block mb-3" >General Info</strong>
                    <div className="col-lg-12" >
                      <div className="form-group field-box mb-4 mb-md-4" >
                        <label className="form-label">1. Project Name*</label>
                        <input type="text" required />
                      </div>
                    </div>
                    <div className="col-lg-12" >
                      <div className="form-group field-box  mb-4 mb-md-4" >
                        <label className="form-label">2. Token/Coin Ticker*</label>
                        <input type="text" required />
                      </div>
                    </div>
                    <div className="col-lg-12" >
                      <div className="form-group field-box  mb-4 mb-md-4" >
                        <label className="form-label">3. Project Official Website* </label>
                        <input type="text" required />
                      </div>
                    </div>
                    <div className="col-lg-12" >
                      <div className="form-group field-box   mb-4 mb-md-4" >
                        <label className="form-label">4. What is the nature of the Project?*</label>
                        <select required>
                          <option disabled hidden value=''> --Select--</option>
                          <option> DeFi</option>
                          <option> NFT</option>
                          <option> Stable Coin</option>
                          <option> L1/L2 Protocols</option>
                          <option> Dapp</option>
                          <option> Currency</option>
                          <option> PoW Coins</option>
                          <option> GameFi/Metaverse</option>
                          <option> Infrastructure (Bridge, Oracle, EVM, etc.)</option>
                          <option> Other </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-12" >
                      <div className="form-group field-box  mb-4 mb-md-4" >
                        <label className="form-label">5. Have you been in discussion with other CEXs regarding listings, please specify:* </label>
                        <input type="text" required />
                      </div>
                    </div>
                    <div className="col-lg-12" >
                      <div className="form-group field-box  mb-4 mb-md-4" >
                        <label className="form-label">6. Target timeline for listing:* </label>
                        <input type="text" required />
                      </div>
                    </div>
                    <div className="col-lg-12" >
                      <div className="form-group field-box  mb-4 mb-md-4" >
                        <label className="form-label">7. Referred by (fipnext officials, if no please input "N/A"):* </label>
                        <select required>
                          <option disabled hidden> --Select--</option>
                          <option> Yes</option>
                          <option> No </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-12" >
                      <div className="form-group field-box  mb-4 mb-md-4" >
                        <label className="form-label">8. If you are working with any third-party listing agent, please specify: </label>
                        <input type="text" />
                      </div>
                    </div>
                    <div className="col-lg-12" >
                      <div className="form-group field-box  mb-4 mb-md-4" >
                        <label className="form-label">9. If you are working with any liquidity service providers (market makers), please specify:* </label>
                        <input type="text" required />
                      </div>
                    </div>

                    <div className="row justify-content-center mt-md-4 mt-4" >
                      <div className="col-6 " >
                        <button className="btn btn-light w-100  text-center btn-xl d-block" type="button" onClick={() => ChangeScreens('step1')} > <span>Back</span> </button>
                      </div> <div className="col-6 " >
                        <button className="btn btn-gradient w-100  text-center btn-xl d-block" type="submit" > <span>Next</span> </button>
                      </div>
                    </div>

                  </div>
                </form>
              </div>

              {/* step 3 */}
              <div className={`create-item-wrapper plicy_sec mb-3 ${formSteps.step3 ? '' : 'd-none'}`}>
                <form className="" >
                  <p>The name and photo associated with your Google account will be recorded when you upload files and submit this form. Only the email you enter is part of your response. </p>
                  <h6 className="text-danger mb-4 " >* Indicates required question</h6>


                  <strong className="d-block mb-2" >Necessary Documents Upload</strong>
                  <p>Please be aware that all documents below are necessary for your listing, for saving time and keep the high efficiency, please try to prepare the documents in advance if you miss any one of them.</p>



                  <div className="row" >

                    <br />
                    <div className="col-lg-12" >
                      <strong className="d-block mb-2" > 1. Due Diligence Info Collection Form* </strong>
                      <p className="mb-2" >Download the full DD Info Collection form at</p>

                      <a rel="noreferrer" href="https://docs.google.com/spreadsheets/" target="_blank" className="text-warning d-block " >https://docs.google.com/spreadsheets/</a>
                      <br />
                      <div className="form-group field-box mb-4 mb-md-4" >
                        <label className="form-label">Please fill in as much information as you can using the original spreadsheet and upload your submission here </label>
                        <input type="file" className=" " />
                      </div>
                    </div> <br /><br />


                    <div className="col-lg-12" >
                      <div className="form-group field-box  mb-4 mb-md-4" >
                        <label className="form-label">2. The incorporation registration documents of your project's major legal entity*</label>
                        <input type="file" className=" " />
                      </div>
                    </div><br /><br />

                    <div className="col-lg-12" >
                      <div className="form-group field-box  mb-4 mb-md-4" >
                        <label className="form-label">3. Legal opinion recognizing your project tokens as utility tokens (Formal legal opinion
                          issued by established law firms in the following jurisdictions: US, UK, EU, HK and Singapore)* </label>
                        <p className="mb-2" >To launch a project on fipnext, a legal opinion is required. If the legal opinion is not in English, you must
                          provide both the original document and a certified translation of the document into English. Please ensure
                          that the translation is ready before the signing date of the final listing agreement.
                        </p>
                        <p className="mb-2" >For detailed guidelines on the legal opinion required for projects to launch on fipnext, please refer to the
                          document provided here: [Link to Guidelines on Legal Opinion]</p>
                        <input type="file" className=" " />
                      </div>
                    </div> <br /><br />


                    <div className="col-lg-12" >
                      <div className="form-group field-box  mb-4 mb-md-4" >
                        <label className="form-label">4. Project White Paper (PDF)*</label>
                        <input type="file" className=" " />
                      </div>
                    </div><br /><br />

                    <div className="col-lg-12" >
                      <div className="form-group field-box  mb-4 mb-md-4" >
                        <label className="form-label">5. Please download the NDA from below, and upload with a signed version</label>
                        <a rel="noreferrer" href="https://drive.google.com/file/d/1JB3AQySshCRnQ74WnrbRf-XyixqWoPRW/view?usp=share_link" target="_blank" className="text-warning d-block mb-3 " >https://drive.google.com/file</a>
                        <input type="file" className=" " />
                      </div>
                    </div> <br /><br />


                    <div className="col-lg-12" >
                      <div className="form-group field-box  mb-4 mb-md-4" >
                        <label className="form-label">6. The third-party code security review/auditing report issued by established security firms
                          for your token and product/protocol:*</label>
                        <p className="mb-2" >If you don't have the code audit report now, please be sure to make it ready before the signing date of the
                          final listing agreement.</p>
                        <input type="file" className=" " />
                      </div>
                    </div> <br /><br />

                    <div className="col-lg-12" >
                      <div className="form-group field-box  mb-4 mb-md-4" >
                        <label className="form-label">7. Please provide the KYC information for at least 3 key project members (national ID or passport copies)</label>
                        <input type="file" className=" " />
                      </div>
                    </div> <br /><br />




                    <div className="col-lg-12" >
                      <div className="form-group field-box  mb-4 mb-md-4" >
                        <label className="form-label">Thank you for choosing fipnext! If there is anything else you would like to add, please feel free to leave below.</label>
                        <input type="text" className=" " placeholder="Your answer" />
                      </div>
                    </div> <br /><br />

                    <div className="row justify-content-center mt-md-4 mt-4" >
                      <div className="col-6 " >
                        <button className="btn btn-light w-100  text-center btn-xl d-block" type="button" onClick={() => ChangeScreens('step2')} > <span>Back</span> </button>
                      </div> <div className="col-6 " >
                        <button className="btn btn-gradient w-100  text-center btn-xl d-block" type="button" onClick={() => console.log('Form Submit')} > <span>Submit</span> </button>
                      </div>
                    </div>

                  </div>
                </form>
              </div>


            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CoinApply;
