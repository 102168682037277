import React, { useState, useEffect, useContext } from "react";
import AuthService from "../../Api_Module/Api_Services/AuthService";
import { alertErrorMessage } from "../../UtilityComponent/CustomAlertMessage";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import LoaderHelper from "../../UtilityComponent/Loading/LoaderHelper";
import Tree from "../ReferralPage/Tree";
import MainTable from "./MainTable";
import { ProfileContext } from "../../Context";
import moment from 'moment';

const ReferralList = () => {

  // ********* Context ********** //
  const { firstName, setFirstName, email, setEmail, lastName, setLastName, setMobile, mobile, userImage, handleUserDetials, checkSignId, countryCode, setCountryCode, code, setCode } = useContext(ProfileContext);

  const [refferralData, setRefferralData] = useState([]);
  const [refferralDataTree, setRefferralDataTree] = useState([]);
  const [rewardHistory, setRewardHistory] = useState([]);
  const [transactions, setTransactions] = useState([]);
  console.log(code)
  useEffect(() => {
    handleReferralLink();
    userCommissionList(code);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const handleReferralLink = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.referralLink().then(async (result) => {
      if (result?.success) {
        // refferralList(result?.data);
        handleReferralTree(result?.data);

      }
    });
  };
  const handleReferralTree = async (code) => {
    try {
      LoaderHelper.loaderStatus(true);
      const result = await AuthService.referralTree(code)
      if (result?.success) {
        setRefferralDataTree(result?.data.tree);
        setRefferralData(result?.data.table)
      } else {
        alertErrorMessage(result?.message);
      }
    } catch (error) {
      alertErrorMessage(error?.message);
    }
    finally { LoaderHelper.loaderStatus(false); }
  };

  const refferralList = async (code) => {
    try {
      LoaderHelper.loaderStatus(true);
      const result = await AuthService.userReferralList(code)
      if (result?.success) {
        setRefferralData(result?.data);
      } else {
        alertErrorMessage(result?.message);
      }
    } catch (error) {
      alertErrorMessage(error?.message);
    }
    finally { LoaderHelper.loaderStatus(false); }
  };

  const userCommissionList = async (code) => {
    try {
      console.log(code)
      LoaderHelper.loaderStatus(true);
      const result = await AuthService.userCommissionList(code)
      if (result?.success) {
        setTransactions(result?.data);
      } else {
        alertErrorMessage(result?.message);
      }
    } catch (error) {
      alertErrorMessage(error?.message);
    }
    finally { LoaderHelper.loaderStatus(false); }
  };

  return (
    <>
      <section className="inner-page-banner">
        <div className="container">
          <div className="inner text-center">
            <h1 className="title text-gradient">
              Referal List
            </h1>
            <nav className="mt-4">
              <ol className="breadcrumb justify-content-center">
                <li className="breadcrumb-item">
                  <Link to="/ReferralPage">Referral Program</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Referral List
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <section className="">
        <div className="container">
          <div className="d-flex-between mb-5">
            <ul className="nav custom-tabs">
              {/* <li><a className=" active" data-bs-toggle="tab" href="#tt_history"> Referred Friends</a> </li> */}
              {/* <li><a data-bs-toggle="tab" href="#funds">Reward History</a></li> */}
            </ul>
          </div>
          <div className="tab-content custom-tab-content p-0">
            {/* <div className="tab-pane fade show container  form-field-wrapper table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded" id="funds">
              <div className="table-responsive">
                <table className="table ">
                  <thead style={{ backgroundColor: "#252f30" }}>
                    <tr>
                      <th>Sr No.</th>
                      <th>ASSETS</th>
                      <th>Available Balance</th>
                      <th>Locked</th>
                      <th>Total</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rewardHistory?.length > 0 ? (
                      rewardHistory.map((item, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item?.short_name}</td>
                          <td>{item?.balance?.toFixed(8)}</td>
                          <td>{item?.locked_balance?.toFixed(8)}</td>
                          <td>
                            {parseFloat(
                              item?.balance + item?.locked_balance
                            )?.toFixed(8)}
                          </td>

                        </tr>
                      ))
                    ) : (
                      <tr rowSpan="5">
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <img src="/images/not_found.svg" width="150px" height="90px" alt="" />
                          <small>No Data Available</small>
                        </div>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div> */}
            <div className="mb-5 tab-pane container fade show active form-field-wrapper table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded" id="tt_history">
            <div className="table-responsive mb-3">
              <h5 className="px-5 py-3 text-center">Commission Transaction's</h5>
              <table className="table ">
                  <thead style={{ backgroundColor: "#252f30" }}>
                    <tr>
                      <th>UID</th>
                      <th>Transaction ID</th>
                      <th>Investment (FIP Coins)</th>
                      <th>Percentage </th>
                      <th>Commission</th>
                      <th>Settlement Date</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactions?.length > 0 ? (
                      transactions.map((item, index) => (
                        <tr>
                          <td>{item.from_user.code}</td>
                          <td>{item.transaction_id}</td>
                          <td>{item.transaction_amount.toFixed(8)}</td>
                          <td>{item?.percentage}% (Lv.{item?.level})</td>
                          <td>
                            ${parseFloat(
                              item?.commission_amount
                            )?.toFixed(2)}
                          </td>
                          <td>
                            {moment(item.settlement_date).format('YYYY-MM-DD')}
                          </td>
                          <td>
                            {item?.status}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr rowSpan="5">
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <img src="/images/not_found.svg" width="150px" height="90px" alt="" />
                          <small>No Data Available</small>
                        </div>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="table-responsive mb-5">
                <MainTable nestedData={refferralData}/>
                {/* <table className="table ">
                  {refferralData?.length > 0 && <thead style={{ backgroundColor: "#252f30" }} >
                    <tr>
                      <th>Sr No.</th>
                      <th>Registration Date</th>
                      <th>Name</th>
                      <th>Mobile Number</th>
                      <th>Email</th>
                      <th>KYC Status</th>
                      <th>Total Investment</th>
                    </tr>
                  </thead>}
                  <tbody>
                    {refferralData?.length > 0 ?
                      refferralData.map((item, index) => (
                        <tr key={index} >
                          <td className="color-grey">{index + 1}</td>
                          <td className="color-grey">
                            {<Moment date={item?.createdAt} format="DD/MM/ YYYY hh:mm" />}
                          </td>
                          <td className="color-grey">{item?.firstName + item?.lastName || "----"}</td>
                          <td className="color-grey">{item?.mobileNumber || "----"}</td>
                          <td className="color-grey">{item?.emailId || "----"}</td>
                          <td className="color-grey">{item?.kycVerified === 0 ? "Not Submitted" : item?.kycVerified === 1 ? "Pending" : item?.kycVerified === 2 ? "Approved" : "Rejected"}</td>
                          <td className="color-grey">{item?.invested_amount || "----"}</td>
                        </tr>
                      ))
                      :
                      <tr rowSpan="5">
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <img src="/images/not_found.svg" width="150px" height="90px" alt="" />
                          <small>No Data Available</small>
                        </div>
                      </tr>}
                  </tbody>
                </table> */}
              </div>
              <div className="table-responsive">
                <Tree treeData={refferralDataTree} />
              </div>
              
            </div>

          </div>
        </div>
      </section>
    </>
  );
};

export default ReferralList;
