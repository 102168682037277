import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { matchPassword, notEqualsZero, validPassword, validPasswordLength } from "../../UtilityComponent/Validation";
import { alertErrorMessage, alertSuccessMessage, alertWarningMessage } from "../../UtilityComponent/CustomAlertMessage";
import LoaderHelper from "../../UtilityComponent/Loading/LoaderHelper";
import AuthService from "../../Api_Module/Api_Services/AuthService";
import DefaultInput from "../../UtilityComponent/DefaultInput";

const SecurityPage = () => {
    const [setActiveTab] = useOutletContext()
    const [togglePass, setTogglePass] = useState({ currPass: false, newPass: false, confPass: false });
    const [password, setPassword] = useState('');
    const [conPassword, setConPassword] = useState('');
    const [oldpassword, setOldpassword] = useState('');
    const navigate = useNavigate();

    const handleInputChange = (event) => {
        switch (event.target.name) {
            case "password":
                setPassword(event.target.value);
                break;
            case "conPassword":
                setConPassword(event.target.value);
                break;
            case "oldpassword":
                setOldpassword(event.target.value);
                break;
            default:
                break;
        }
    };

    const handleLogOut = () => {
        navigate("/login");
        sessionStorage.clear();
        window.location.reload();
    };

    const handleSecutity = async () => {
        if (validPassword(oldpassword) !== undefined) { alertWarningMessage('Please enter valid current password'); return }
        else if (validPassword(password) !== undefined) { alertWarningMessage('Please enter valid new password'); return }
        else if (matchPassword(password, conPassword) !== undefined) { alertWarningMessage('Password does not match'); return }
        try {
            LoaderHelper.loaderStatus(true);
            const result = await AuthService.changePassword(oldpassword, password, conPassword)
            if (result?.success) {
                alertSuccessMessage("Password has been changed successfully.");
                setTimeout(() => {
                    handleLogOut();
                }, 1000);

            } else {
                // alertErrorMessage(result?.message);
            }
        } catch (error) {
            alertErrorMessage(error?.message);
        }
    };

    const handlePassToggle = (type) => {
        switch (type) {
            case 'currPass':
                setTogglePass({ ...togglePass, currPass: !togglePass.currPass })
                break;
            case 'newPass':
                setTogglePass({ ...togglePass, newPass: !togglePass.newPass })
                break;
            case 'confPass':
                setTogglePass({ ...togglePass, confPass: !togglePass.confPass })
                break;

            default:
                break;
        }
    };

    useEffect(() => {
        let URL = window.location.href?.split('/');
        let route = URL.pop();
        setActiveTab(route)

    }, []);

    return (
        <>
            <div className="tab-pane" id="SecurityPill" role="tabpanel" aria-labelledby="Security-pill">
                <div className="upload-formate mb-6 d-flex justify-content-center align-items-center">
                    <div>
                        <h3 className="mb-1 text-center">
                            Security
                        </h3>
                        <p className="formate mb-0">
                            Fill the form below to change your password.
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-lg-8 m-auto">
                        <div className="form-field-wrapper switch_btn  border-dashed border-gray-300 bg-lighten card-rounded p-4">
                            <form>
                                <div className="row">
                                    <div className="col-md-12 mb-4">
                                        <label htmlFor="text" className="form-label" >Current Password <span className="text-danger">*</span></label>
                                        <div className="field-otp-box">
                                            <DefaultInput type={togglePass.currPass ? "text" : "password"} name="oldpassword" aria-describedby="password" placeholder="Enter Current Password" value={oldpassword} onChange={handleInputChange} />

                                            <a className="show_password opt_btn btn-sm" href="#/" onClick={() => handlePassToggle("currPass")}>
                                                {togglePass.currPass ? <i className="ri-eye-off-line" /> : <i className="ri-eye-line" />}
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <label htmlFor="text" className="form-label">New Password <span className="text-danger">*</span></label>
                                        <div className="field-otp-box">
                                            <DefaultInput type={togglePass.newPass ? "text" : "password"} name="password" placeholder="Enter New Password" value={password} errorstatus={(validPassword(password) !== undefined && notEqualsZero(password) && validPasswordLength(password)) ? 'true' : 'false'} errormessage={validPassword(password) && validPasswordLength(password)} onChange={handleInputChange} />

                                            <a className="show_password opt_btn btn-sm" onClick={() => handlePassToggle("newPass")} href="#/" >
                                                {togglePass.newPass ? <i className="ri-eye-off-line" /> : <i className="ri-eye-line" />}
                                            </a>

                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <label htmlFor="text" className="form-label">Confirm New Password <span className="text-danger">*</span></label>
                                        <div className="field-otp-box">
                                            <DefaultInput type={togglePass.confPass ? "text" : "password"} name="conPassword" placeholder="Enter Confirm Password" value={conPassword} errorstatus={(notEqualsZero(conPassword) && matchPassword(password, conPassword)) ? 'true' : 'false'} errormessage={matchPassword(password, conPassword)} onChange={handleInputChange} />

                                            <a className="show_password opt_btn btn-sm" href="#/" onClick={() => handlePassToggle("confPass")}>
                                                {togglePass.confPass ? <i className="ri-eye-off-line" /> : <i className="ri-eye-line" />}
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-4 mt-4">
                                        <div className="field-box">
                                            {(!oldpassword || !password || !conPassword) ? <button className="btn btn-gradient w-100 justify-content-center btn-medium " type="button" disabled  ><span>Submit</span></button> : <button className="btn btn-gradient w-100 justify-content-center btn-medium " type="button"
                                                onClick={handleSecutity}
                                            ><span>Submit</span></button>}
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SecurityPage;