import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import Moment from "react-moment";
import LoaderHelper from "../../UtilityComponent/Loading/LoaderHelper";
import AuthService from "../../Api_Module/Api_Services/AuthService";
import { alertErrorMessage, alertSuccessMessage, alertWarningMessage } from "../../UtilityComponent/CustomAlertMessage";
import { $ } from "react-jquery-plugin";
import { ProfileContext } from "../../Context";
import DataTableBase from "../../UtilityComponent/DataTable";
import copy from "copy-to-clipboard";
import jsPDF from "jspdf";


const FundPage = () => {
  const [fundData, setfundData] = useState([]);
  const [allfundData, setallFundData] = useState([]);
  const [estimatedportfolio, setEstimatedportfolio] = useState();
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [selectedDeposit, setSelectDeposit] = useState("");
  const [selectedChain, setSelectedchain] = useState("");
  const [tradeHistory, settradeHistory] = useState([]);
  const [minDeposit, setminDeposit] = useState();
  const [walletHisDetail, setwalletHisDetail] = useState();
  const [tradeHisDetails, settradeHisDetails] = useState();
  const [currencyId, setcurrencyId] = useState();
  const [allChain, setallChain] = useState();
  const [walletAddress, setWalletAddress] = useState('');
  const [DepositHistory, setDepositHistory] = useState([]);
  const [WithdrawalHistory, setWithdrawalHistory] = useState([]);
  const [buySellHist, setBuySellHist] = useState([]);
  const [wihtdrawalDetails, setwihtdrawalDetails] = useState({ minWithdrawal: '', maxWithdrawal: '', withdrawalFee: '' });
  const [otp, setOtp] = useState('');
  const [skipCount, setSkipCount] = useState(0);
  const [limitCount, setLimitCount] = useState(0);
  const [bankDetails, setBankDetails] = useState({});
  const [amountInr, setAmountInr] = useState("");
  const [fipcoins, setFipcoins] = useState("");
  const [transactionNumber, setTransactionNumber] = useState();
  const [userComission, setUserComission] = useState([]);
  const { email, mobile, handleUserDetials, code } = useContext(ProfileContext);

  console.log(code)

  useEffect(() => {
    getUserfunds();
    VerifyDeposit();
    EstimatedPortfolio();
    // getBankDetails();
    handleUserDetials();
    fetchFipcoins(code);
  }, [code]);


  // ********* Get OTP ********** //
  const handleGetOtp = async (signId) => {
    if (selectedCurrency !== "INR") {
      if (!walletAddress || !withdrawAmount) {
        alertErrorMessage('Please enter withdraw amount and Wallet address');
        return;
      } else if (CheckWalletAddress) {
        alertErrorMessage('Please enter valid Wallet Address')
        return;
      }
    }
    LoaderHelper.loaderStatus(true);
    await AuthService.withdrawalOtp(signId, walletAddress, withdrawAmount, selectedCurrency, 'withdrawal').then(async (result) => {
      if (result?.success) {
        startTimer();
        alertSuccessMessage(result?.message);
      }
    });
  };

  // ********* Funds Data ********** //
  const getUserfunds = async (orderId) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getUserfunds(orderId).then((result) => {
      if (result?.success) {
        setfundData(result?.data)
        setallFundData(result?.data)
      }
    })
  };

  // ********* Funds Data ********** //
  const getBuySellHistory = async (skip, limit) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.quickBuySellHistory(skip, 10).then((result) => {
      if (result?.success) {
        if (result?.data?.length > 0) {
          setSkipCount(skip);
          setLimitCount(limit);
          setBuySellHist(result?.data)
          return;
        } else if (skip !== 0) {
          alertWarningMessage('No more data found')
          return;
        }

      }
    })
  };

  // *********comission History ********** //
  const getComissionHistory = async (skip, limit) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.comissionHistory(skip, 10).then((result) => {
      if (result?.success) {
        if (result?.data?.length > 0) {
          setSkipCount(skip);
          setLimitCount(limit);
          // setUserComission(result?.data)
          let depositData = result?.data?.filter((item) => item?.transaction_type === "CREDITED_BY_ADMIN")
          let withdrawalData = result?.data?.filter((item) => item?.transaction_type === "DEBITED_BY_ADMIN")
          setWithdrawalHistory(withdrawalData);
          setDepositHistory(depositData);
          return;
        } else if (skip !== 0) {
          alertWarningMessage('No more data found')
          return;
        }

      }
    })
  };
  const [adminTrading, setAdminTrading] = useState([]);
  // *********comission History ********** //
  const getUserAdminTrading = async (skip, limit) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.userAdminTrading(skip, 10).then((result) => {
      if (result?.success) {
        if (result?.data?.length > 0) {
          setSkipCount(skip);
          setLimitCount(limit);
          setAdminTrading(result?.data?.reverse())
          return;
        } else if (skip !== 0) {
          alertWarningMessage('No more data found')
          return;
        }

      }
    })
  };

  // ********* Coin Deposit Address ********** //
  const getDepostAddress = async (currId, chain) => {
    setSelectedchain(chain);
    setSelectDeposit("");
    LoaderHelper.loaderStatus(true);
    await AuthService.generateAddress(currId, chain).then((result) => {
      if (result?.success) {
        setSelectDeposit(result?.data)
      } else {
        $('#Deposit_modal')?.modal('hide');
      }
    })
  };

  // ********* Estimated Portfolio********** //
  const EstimatedPortfolio = async () => {
    await AuthService.estimatedPortfolio().then((result) => {
      if (result?.success) {
        setEstimatedportfolio(result?.data)
      };
    })
  };
  // ********* Estimated Portfolio********** //
  const getBankDetails = async () => {
    await AuthService.getUserBankDetails().then((result) => {
      if (result?.success) {
        setBankDetails(result?.data[0])
      };
    })
  };

  // ********* Trade History ********** //
  const TradeHistory = async (skip, limit) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.tradeHistory(skip, 10).then((result) => {
      if (result?.success) {
        if (result?.data?.length > 0) {
          setSkipCount(skip);
          setLimitCount(limit);
          settradeHistory(result?.data);
          return;
        } else if (skip !== 0) {
          alertWarningMessage('No more data found')
          return;
        }
      }
    })
  };
  // ********* Deposit Wallet History ********** //
  const DepositWalletHistory = async (skip, limit) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.walletDepositHistory(skip, 10).then(async (result) => {
      if (result?.success) {
        if (result?.data?.length > 0) {
          setSkipCount(skip);
          setLimitCount(limit);
          setDepositHistory(result?.data);
          return;
        } else if (skip !== 0) {
          alertWarningMessage('No more data found')
        }
      }
    });
  };
  // ********* Deposit Wallet History ********** //
  const WithdrawalWalletHistory = async (skip, limit) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.walletWithdrawalHistory(skip, 10).then(async (result) => {
      if (result?.success) {
        if (result?.data?.length > 0) {
          setSkipCount(skip);
          setLimitCount(limit);
          setWithdrawalHistory(result?.data);
          return;
        } else if (skip !== 0) {
          alertWarningMessage('No more data found')
        }
      }
    });
  };

  // ********* Coin Min Deposit Data ********** //
  const coinDetails = async (currId, type) => {
    await AuthService.coinDetails(currId).then((result) => {
      if (result?.success) {
        setminDeposit(result?.data?.min_deposit);
        wihtdrawalDetails.minWithdrawal = result?.data?.min_withdrawal
        wihtdrawalDetails.maxWithdrawal = result?.data?.max_withdrawal
        wihtdrawalDetails.withdrawalFee = result?.data?.withdrawal_fee

        if (type === 'deposit') {
          if (result?.data?.deposit_status === "INACTIVE") {
            alertErrorMessage('Deposit is disabled for this Coin');
            return;
          } else {
            $("#Deposit_modal").modal("show");
          }
        }

        if (type === 'withdrawal') {
          if (result?.data?.withdrawal_status === 'INACTIVE') {
            alertErrorMessage('Withdrawal is disabled for this Coin');
            return;
          } else {
            $("#Withdraw_modal").modal("show");
          }

        }
      }
    }
    )
  };

  // ********* Withdrawal Currency********** //
  const WithdrawalCurrency = async (currencyId, selectedChain, withdrawAmount, walletAddress, otp, signId) => {
    setOtp('');
    if (!email) {
      alertErrorMessage('Please update your email in Profile section for Withdrawal');
      return
    }
    LoaderHelper.loaderStatus(true);
    await AuthService.withdrawalCurrency(currencyId, selectedChain, withdrawAmount, walletAddress, otp, signId).then((result) => {
      if (result?.success) {
        getUserfunds()
        alertSuccessMessage(result?.message);
      }
    })
  };

  //******** Auto Call Verify -Deposit after evry 5 minute **********//
  const VerifyDeposit = async () => {
    const ref = window.location.href.split("/");
    ref[ref.length - 1] === 'FundPage' && setTimeout(VerifyDeposit, 300000);
    await AuthService.verifyDeposit().then(async (result) => {
      if (result?.success) {

      }
    });
  };

  //******** Wallet Address Validation **********//
  const [CheckWalletAddress, setCheckWalletAddress] = useState(false);
  const handleWalletAddress = (e) => {
    setCheckWalletAddress(true);
    setWalletAddress(e.target.value)
    if (selectedChain === 'BEP20' || selectedChain === 'RIK' || selectedChain === 'RIK20') {
      const bep20Regex = /^(0x)?[0-9a-fA-F]{40}$/;
      if (bep20Regex.test(e.target.value)) {
        setCheckWalletAddress(false);
      }
    } else if (selectedChain === 'TRC20') {
      const trc20Regex = /^(T|t)[A-Za-z1-9]{33}$/;
      if (trc20Regex.test(e.target.value)) {
        setCheckWalletAddress(false);
      }
    }
  };

  //****** */ Function to start the timer and disable the button***********//
  const [timer, setTimer] = useState(30);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const startTimer = () => {
    setIsButtonDisabled(true);
    setTimer(60);
  };

  useEffect(() => {
    let interval;
    if (timer > 0 && isButtonDisabled) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setIsButtonDisabled(false);
    }

    return () => {
      clearInterval(interval);
    };
  }, [timer, isButtonDisabled]);


  const handleWithdrawModal = (data) => {
    setcurrencyId(data.currency_id);
    setallChain(data.chain);
    setSelectedCurrency(data.short_name);
    setSelectedchain(data.chain[0]);
    setWalletAddress('');
    setWithdrawAmount('');
    coinDetails(data?.currency_id, 'withdrawal');
    setCheckWalletAddress(false);
    setOtp('')
  };

  const handleDepositModal = (data) => {
    coinDetails(data?.currency_id, 'deposit');
    setSelectedCurrency(data.short_name);
    setallChain(data.chain);
    data?.short_name !== "INR" && getDepostAddress(data.currency_id, data.chain[0]);
    setcurrencyId(data.currency_id)
  };

  const HandlePairSearch = (e) => {
    let input = e.target.value?.toLowerCase();
    let filteredData = allfundData?.filter((item) => {
      return item?.short_name?.toLowerCase()?.includes(input)
    });
    setfundData(filteredData)
  };

  const fundsAction = (row) => {
    return <div className="d-flex align-items-center justify-content-center flex-wrap">
      <a href="#/" className="tb_btn badge badge-success m-1" onClick={() => handleDepositModal(row)}>Deposit</a>
      <a href="#/" className="tb_btn  badge badge-danger" onClick={() => handleWithdrawModal(row)}>Withdraw</a>
    </div>

  };

  const quickBuySellColumn = [
    { name: <>Sr No.</>, compact: 1, selector: (row, index) => index + skipCount + 1 },
    { name: <>Date/Time</>, selector: row => <Moment date={row?.createdAt} format="DD-MM-YYYY hh:mm" /> },
    { name: <>From Coin</>, selector: row => row.from, },
    { name: <>To Coin</>, selector: row => row.to, },
    { name: <>Amount</>, selector: row => parseFloat(row.get_amount?.toFixed(5)), },
    { name: <>Paid Amount</>, selector: row => parseFloat(row.pay_amount?.toFixed(5)), },
    { name: <>Side</>, selector: row => row.side, },
  ];
  const userComissionColumn = [
    { name: <>Sr No.</>, compact: 1, selector: (row, index) => index + skipCount + 1 },
    { name: <>Date/Time</>, selector: row => <Moment date={row?.createdAt} format="DD-MM-YYYY hh:mm" /> },
    { name: <>Currency</>, selector: row => row?.currency, },
    { name: <>Amount</>, selector: row => parseFloat(row.amount?.toFixed(5)), },
    { name: <>Transaction Type</>, selector: row => row.transaction_type, },
    { name: <>Status</>, selector: row => row.status, },
  ];

  const adminTradingColumn = [
    { name: <>Sr No.</>, compact: 1, selector: (row, index) => index + 1 },
    { name: <>Date/Time</>, selector: row => <Moment date={row?.createdAt} format="DD-MM-YYYY hh:mm" /> },
    { name: <>Currency Pair</>, selector: row => row.currency_pair, },
    { name: <>Sell Price</>, selector: row => row.sell_price, },
    { name: <>Buy Price</>, selector: row => row.buy_price, },
    { name: <>Quantity</>, selector: row => row.quantity, },
    { name: <>Net Profit</>, selector: row => <span className={row.net_profit >= 0 ? "text-success" : "text-danger"}>{row.net_profit}</span>, },
    { name: <>Status</>, selector: row => row.status, },
  ];

  const fundsColumn = [
    { name: <>Sr No.</>, compact: 1, selector: (row, index) => index + 1 },
    { name: <>ASSETS</>, selector: row => row.short_name, },
    { name: <>Available Balance</>, wrap: true, sortable: true, selector: row => row.short_name == "FIPCOIN"?fipcoins:parseFloat(row?.balance?.toFixed(8)) },
    { name: <>Locked</>, wrap: true, sortable: true, selector: row => parseFloat(row?.locked_balance?.toFixed(8)) },
    { name: <>Total</>, wrap: true, sortable: true, selector: row => parseFloat((+row?.balance + +row?.locked_balance)?.toFixed(8)), },
    { name: <>Action</>, grow: 2, selector: fundsAction }
  ];

  const tradeHistoryColumn = [
    { name: <>Sr No.</>, compact: 1, selector: (row, index) => index + skipCount + 1 },
    { name: <>Date/Time</>, wrap: true, selector: row => <Moment date={row?.updatedAt} format="DD-MM-YYYY, h:mm:ss" />, },
    { name: <>Trading Currency</>, wrap: true, selector: row => row?.currency },
    { name: <>Side</>, wrap: true, selector: row => row?.side },
    { name: <>Order Type</>, wrap: true, selector: row => row?.order_type, },
    { name: <>Price</>, wrap: true, sortable: true, selector: row => parseFloat(row?.price) },
    { name: <>Executed</>, wrap: true, sortable: true, selector: row => parseFloat(row?.quantity?.toFixed(8)) },
    { name: <>Total</>, wrap: true, selector: row => parseFloat((row?.price * row?.quantity)?.toFixed(5)) },
    { name: <>Transaction Type</>, wrap: true, selector: row => row?.transaction_type },
    { name: <>Fee</>, wrap: true, sortable: true, selector: row => parseFloat(row?.fee?.toFixed(8)) }
  ];

  const withdrawalColumn = [
    { name: <>Sr No.</>, compact: 1, selector: (row, index) => index + skipCount + 1 },
    { name: <>Date/Time</>, selector: row => <Moment date={row?.updatedAt} format="DD-MM-YYYY hh:mm" /> },
    { name: <>Coin</>, selector: row => row.short_name, },
    { name: <>Amount</>, selector: row => parseFloat(row.amount?.toFixed(5)), },
    { name: <>Transaction Type</>, selector: row => row.transaction_type, },
    { name: <>Status</>, selector: row => row.status, },
  ];

  const depositColumn = [
    { name: <>Sr No.</>, compact: 1, selector: (row, index) => index + skipCount + 1 },
    { name: <>Date/Time</>, selector: row => <Moment date={row?.updatedAt} format="DD-MM-YYYY hh:mm" /> },
    { name: <>Coin</>, selector: row => row.short_name, },
    { name: <>Amount</>, selector: row => parseFloat(row.amount?.toFixed(5)), },
    { name: <>Transaction Type</>, selector: row => row.transaction_type, },
    { name: <>Status</>, selector: row => row.status, },
  ];

  
    // *********Export Trade Data In PDF Formats ************* // 
    const exportToPDF = () => {
      const doc = new jsPDF('landscape', 'mm', 'a4');
  
      // Add title
      doc.setFontSize(20);
      doc.setTextColor(40);
      doc.text('Trading Report', 140, 20, { align: 'center' });
  
      // Add a horizontal line below the title
      doc.setLineWidth(0.5);
      doc.line(20, 25, 280, 25);
  
      // Add some spacing
      doc.setFontSize(12);
      doc.setTextColor(60);
      doc.text('Generated on: ' + moment().format("MMMM Do YYYY, h:mm:ss a"), 20, 35);
  
      const tableHeaders = ['Serial No.', 'Date/Time', 'Trading Currency', 'Side', 'Order Type', 'Price', 'Executed', 'Total', 'Fee', "TDS Amount", "TDS In INR"];
      const tableData = tradeHistory.map((trade, index) => [
          index + 1, 
          moment(trade?.createdAt).format("MMMM Do YYYY, h:mm:ss a"), 
          trade?.currency, 
          trade?.side, 
          trade?.order_type, 
          trade?.price, 
          trade?.quantity, 
          trade?.price * trade?.quantity, 
          trade?.fee,
          trade?.tds_amount,
          trade?.tds_in_inr
      ]);
  
      const fixedColumnWidths = [25, 30, 30, 20, 35, 20, 20, 20, 20, 20, 20];
      const columnStyles = {};
      tableHeaders.forEach((_, index) => {
          columnStyles[index] = { cellWidth: fixedColumnWidths[index] };
      });
  
      // Auto table styling
      doc.autoTable({
          head: [tableHeaders],
          body: tableData,
          theme: 'striped', // 'striped', 'grid' or 'plain'
          headStyles: {
              fillColor: [41, 128, 185], // Header background color
              textColor: [255, 255, 255], // Header text color
              fontSize: 10,
              halign: 'center',
              valign: 'middle'
          },
          bodyStyles: {
              fillColor: [245, 245, 245], // Row background color
              textColor: [0, 0, 0], // Row text color
              fontSize: 9,
              halign: 'center',
              valign: 'middle'
          },
          alternateRowStyles: {
              fillColor: [255, 255, 255] // Alternate row background color
          },
          columnStyles: columnStyles,
          startY: 45, // Starting Y position
          margin: { top: 45 }
      });
  
      doc.save('TradeHistory.pdf');
  };
  
  const fetchFipcoins = async (code) => {
    try {
      if(code){
        LoaderHelper.loaderStatus(true);
        const result = await AuthService.userFipcoins(code)
        if (result?.success) {
          if(result.data.length != 0){
            let fipcoin = result.data.reduce((a, b) => {return a + b.transaction_amount}, 0);
            setFipcoins(fipcoin);
          }
          
        } else {
          alertErrorMessage(result?.message);
        }
      }
      
    } catch (error) {
      alertErrorMessage(error?.message);
    }
    finally { LoaderHelper.loaderStatus(false); }
  };
  return (
    <>

      <section className="inner-page-banner wallet_bal_sec">
        <div className="container">
          <div class="card p-md-3">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-md-7">
                  <div class="wallet__details">
                    <div class="wallet__info">Total Portfolio Value</div>
                    <div class="wallet__currency">
                      <div class="wallet__number">{estimatedportfolio} </div>
                    </div>
                    {/* <div class="wallet__price"> $0.00000 </div> */}
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="searchBar custom-tabs"><i class="ri-search-2-line"></i><input type="search" class="custom_search" placeholder="Search Asset" onChange={HandlePairSearch} /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section >
        <div className="container">
          <div className="d-flex-between mb-5 custom_dlflex">
            <ul className="nav custom-tabs overflowx_scroll funds_tab">
              <li><a className=" active" data-bs-toggle="tab" href="#funds" onClick={getUserfunds}>Funds</a></li>
              <li><a data-bs-toggle="tab" href="#tt_history" onClick={() => { getComissionHistory(0, 10); setSkipCount(0); setLimitCount(0) }}> Deposit History</a> </li>
              <li><a data-bs-toggle="tab" href="#withdrwal_history" onClick={() => { getComissionHistory(0, 10); setSkipCount(0); setLimitCount(0) }}> Withdrawal History</a> </li>
              <li><a data-bs-toggle="tab" href="#tradehistory" onClick={() => { TradeHistory(0, 10); setSkipCount(0); setLimitCount(0) }}>Trade History</a></li>
              <li><a data-bs-toggle="tab" href="#adminTradingHistory" onClick={() => { getUserAdminTrading(0, 10); setSkipCount(0); setLimitCount(0) }}>Admin Trading</a></li>
              {/* <li><a data-bs-toggle="tab" href="#comissionhistory" onClick={() => { getComissionHistory(0, 10); setSkipCount(0); setLimitCount(0) }}>Comission Bonus</a></li> */}
            </ul>
            {/* <div className="d-flex-between bidder">
              <span>Estimated Portfolio : </span>
              <div className="d-flex-center ms-1">
                <span className="text-white">{estimatedportfolio}</span>
              </div>
            </div> */}
          </div>
          <div className="tab-content custom-tab-content p-0">
            <div className="tab-pane fade show container active form-field-wrapper table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded" id="funds">
              <div className="table-responsive">
                <DataTableBase columns={fundsColumn} data={fundData} />
              </div>
            </div>

            {/* Admin Trading List */}
            <div className="tab-pane fade container form-field-wrapper table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded" id="adminTradingHistory">
              <div className="table-responsive">
                <DataTableBase columns={adminTradingColumn} data={adminTrading} />
              </div>
            </div>

            {/* Deposit History */}
            <div className="tab-pane container fade form-field-wrapper table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded" id="tt_history">
              <div className="table-responsive">
                <DataTableBase className="rdt_Table rdt_TableHeader rdt_TableHead rdt_TableHeadRow rdt_TableRow rdt_TableCol" columns={depositColumn} data={DepositHistory} onRowClicked={(row) => { setwalletHisDetail(row); $("#wallet_history").modal('show'); }} />
                {DepositHistory?.length !== 0 &&
                  <div className="container pt-3 pb-4  table_control" >
                    <div className=" row align-items-center " >
                      <div className=" row align-items-center justify-content-end" >
                        <div className="btn-group btn-group-mini justify-content-end " role="group" aria-label="Basic radio toggle button group">
                          <input type="button" className="btn-check" name="btnradio" id="btnradio250" autoComplete="off" disabled={skipCount <= 1} />
                          <label className="btn " htmlFor="btnradio250" onClick={() => { getComissionHistory(skipCount - 10, limitCount - 10) }}>Previous</label>

                          <input type="button" className="btn-check" name="btnradio" id="btnradio375" autoComplete="off" disabled={DepositHistory?.length < 10} />
                          <label className="btn " htmlFor="btnradio375" disabled onClick={() => { getComissionHistory(skipCount + 10, limitCount + 10) }}>Next</label>
                        </div>
                      </div>
                    </div>
                  </div>}
              </div>
            </div>

            {/* Withdrawal History */}
            <div className="tab-pane container fade form-field-wrapper table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded" id="withdrwal_history">
              <div className="table-responsive">
                <DataTableBase className="rdt_Table rdt_TableHeader rdt_TableHead rdt_TableHeadRow rdt_TableRow rdt_TableCol" columns={withdrawalColumn} data={WithdrawalHistory} onRowClicked={(row) => { setwalletHisDetail(row); $("#wallet_history").modal('show'); }} />
                {WithdrawalHistory?.length !== 0 &&
                  <div className="container pt-3 pb-4  table_control" >
                    <div className=" row align-items-center " >
                      <div className=" row align-items-center justify-content-end" >
                        <div className="btn-group btn-group-mini justify-content-end " role="group" aria-label="Basic radio toggle button group">
                          <input type="button" className="btn-check" name="btnradio" id="btnradio250" autoComplete="off" disabled={skipCount <= 1} />
                          <label className="btn " htmlFor="btnradio250" onClick={() => { getComissionHistory(skipCount - 10, limitCount - 10) }}>Previous</label>

                          <input type="button" className="btn-check" name="btnradio" id="btnradio375" autoComplete="off" disabled={WithdrawalHistory?.length < 10} />
                          <label className="btn " htmlFor="btnradio375" disabled onClick={() => { getComissionHistory(skipCount + 10, limitCount + 10) }}>Next</label>
                        </div>
                      </div>
                    </div>
                  </div>}
              </div>
            </div>

            {/* *****Trade History******** */}
            <div className="tab-pane container fade form-field-wrapper table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded" id="tradehistory">
              <div className="table-responsive">
                <div className="d-flex justify-content-end" onClick={exportToPDF} >
                  <button className="m-2 btn btn-dark btn-sm ">Download Trade History</button>
                </div>
                <DataTableBase subHeaderWrap headerWrap columns={tradeHistoryColumn} pointerOnHover data={tradeHistory} onRowClicked={(row) => { settradeHisDetails(row); $("#trade_history").modal('show'); }} />

                {tradeHistory?.length !== 0 &&
                  <div className="container pt-3 pb-4  table_control" >
                    <div className=" row align-items-center justify-content-end" >
                      <div className="col-md-4" >
                        <div className="btn-group btn-group-mini justify-content-end " role="group" aria-label="Basic radio toggle button group">
                          <input type="button" className="btn-check" name="btnradio" id="btnradio250" autoComplete="off" disabled={skipCount <= 1} />
                          <label className="btn " htmlFor="btnradio250" onClick={() => { TradeHistory(skipCount - 10, limitCount - 10) }}>Previous</label>
                          <input type="button" className="btn-check" name="btnradio" id="btnradio375" autoComplete="off"
                            disabled={tradeHistory?.length < 10} />
                          <label className="btn " htmlFor="btnradio375" disabled onClick={() => { TradeHistory(skipCount + 10, limitCount + 10) }}>Next</label>
                        </div>
                      </div>
                    </div>
                  </div>}
              </div>
            </div>

            {/* *****Buy Sell history******** */}
            <div className="tab-pane container fade form-field-wrapper table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded" id="BuySellhistory">
              <div className="table-responsive">
                <DataTableBase className="rdt_Table rdt_TableHeader rdt_TableHead rdt_TableHeadRow rdt_TableRow rdt_TableCol" columns={quickBuySellColumn} data={buySellHist} />
                {buySellHist?.length !== 0 &&
                  <div className="container pt-3 pb-4  table_control" >
                    <div className=" row align-items-center justify-content-end" >
                      <div className="col-md-4" >
                        <div className="btn-group btn-group-mini justify-content-end " role="group" aria-label="Basic radio toggle button group">
                          <input type="button" className="btn-check" name="btnradio" id="btnradio250" autoComplete="off" disabled={skipCount <= 1} />
                          <label className="btn " htmlFor="btnradio250" onClick={() => { getBuySellHistory(skipCount - 10, limitCount - 10) }}>Previous</label>
                          <input type="button" className="btn-check" name="btnradio" id="btnradio375" autoComplete="off"
                            disabled={buySellHist?.length < 10} />
                          <label className="btn " htmlFor="btnradio375" onClick={() => { getBuySellHistory(skipCount + 10, limitCount + 10) }}>Next</label>
                        </div>
                      </div>
                    </div>
                  </div>}
              </div>
            </div>

            {/* ****Comission history******** */}
            <div className="tab-pane container fade form-field-wrapper table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded" id="comissionhistory">
              <div className="table-responsive">
                <DataTableBase className="rdt_Table rdt_TableHeader rdt_TableHead rdt_TableHeadRow rdt_TableRow rdt_TableCol" columns={userComissionColumn} data={userComission} />
                {userComission?.length !== 0 &&
                  <div className="container pt-3 pb-4  table_control" >
                    <div className=" row align-items-center justify-content-end" >
                      <div className="col-md-4" >
                        <div className="btn-group btn-group-mini justify-content-end " role="group" aria-label="Basic radio toggle button group">
                          <input type="button" className="btn-check" name="btnradio" id="btnradio250" autoComplete="off" disabled={skipCount <= 1} />
                          <label className="btn " htmlFor="btnradio250" onClick={() => { getComissionHistory(skipCount - 10, limitCount - 10) }}>Previous</label>
                          <input type="button" className="btn-check" name="btnradio" id="btnradio375" autoComplete="off"
                            disabled={buySellHist?.length < 10} />
                          <label className="btn " htmlFor="btnradio375" onClick={() => { getComissionHistory(skipCount + 10, limitCount + 10) }}>Next</label>
                        </div>
                      </div>
                    </div>
                  </div>}
              </div>
            </div>

          </div>
        </div>
      </section >
      <div className="modal fade" id="Deposit_modal" tabIndex="-1" aria-labelledby="Deposit_modalLaebl" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header flex-column px-8">
              <h3 className="modal-title" id="placeBitLaebl">
                Deposit Funds
              </h3>
              <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close">
                <i className="ri-close-fill"></i>
              </button>
            </div>
            <div className="modal-body px-8 py-5">
              <form action="#">
                {selectedCurrency === "INR" ?
                  (
                    <>
                      <h6 class="flex_amount mb-3"> Bank Account Details</h6>
                      <hr />
                      <div class="flex_amount mb-3">
                        <div class="d-flex   tt_item">
                          <strong>Bank Name :</strong> {bankDetails?.bank_name}
                        </div>
                        <div class="d-flex  tt_item">
                          <strong>Account Holder Name :</strong> {bankDetails?.holder_name}
                        </div>
                        <div class=" d-flex  tt_item">
                          <strong>Bank Account Number : </strong>  {bankDetails?.account_number}
                        </div>
                        <div class=" d-flex  tt_item">
                          <strong>Branch Name :</strong>  {bankDetails?.branch}
                        </div>
                        <div class=" d-flex  tt_item">
                          <strong>SWIFT Code/IFSC Code :</strong>  {bankDetails?.ifsc}
                        </div>
                      </div>
                      <div className="form-group mb-2">
                        <input type="text" placeholder="Enter Amount" value={amountInr} onChange={(e) => setAmountInr(e.target.value)} />
                      </div>
                      <div className="form-group mb-2">
                        <input type="text" placeholder="Enter Transaction Number" value={transactionNumber} onChange={(e) => setTransactionNumber(e.target.value)} />
                      </div>
                      <div className="row">
                        <div className="col-md-12 upload-area">
                          <div className="brows-file-wrapper">
                            <input name="file" className="inputfile" type="file" />
                            {true ?
                              <label htmlFor="file" title="No File Choosen">
                                <i className="ri-upload-cloud-line"></i>
                                <span className="text-center mb-2">Choose a File</span>
                                < span className="file-type text-center mt--10">Drag or choose your file to upload</span>
                              </label>
                              : <label htmlFor="file" title="No File Choosen">
                                <i className=" text-success ri-check-double-fill"></i>
                                <span className="text-center mb-2">File Uploaded</span>
                                <span className="file-type text-center mt--10" >localInrImage?.name</span>
                              </label>
                            }
                          </div>
                        </div>
                      </div>
                      <div className="form-group mb-4">
                        <button type="button" className="btn btn-gradient btn-small w-100 justify-content-center mt-4" >
                          <span>Deposit INR</span>
                        </button>
                        <small class="mt-1 d-block text-center fw-small  mt-3 text-center ">
                          <span className="onceDeposit "> Once Deposit It will Take Minimum Two Hours for Confirm </span>
                        </small>
                      </div>
                    </>
                  )
                  :
                  <>
                    <div id="bnm">
                      <div className="btn-group btn_radio_group d-flex justify-content-center align-items-center m-auto " role="group" aria-label="Basic radio toggle button group">
                        {allChain ? allChain?.map((item, index) => {
                          return (
                            <button key={index} type="button" className={`btn btn-outline-primary ${selectedChain === item && "active"}`} htmlFor={item} onClick={() => { getDepostAddress(currencyId, item) }}>
                              {item === 'RIK' ? 'RIK20' : item}
                            </button>)
                        }) : ''}
                      </div>
                      {selectedDeposit ? <>   <img alt="Oops... Error while fetching QR " src={`https://quickchart.io/chart?chs=300x300&cht=qr&chl=${selectedDeposit}&choe=UTF-8`}
                        className="qr_img img-fluid" />
                        <input className="shareUrl-input copy_url js-shareUrl text-center" type="text" readOnly value={selectedDeposit || "Error while fetching deposit address"} onClick={() => { copy(selectedDeposit); alertSuccessMessage("Deposit Address Copied") }}
                        />
                        <small className="text-center d-block mtb-2">
                          Click above to copy the Code.
                        </small></> :
                        <input className="shareUrl-input copy_url js-shareUrl text-center" type="text" readOnly value={selectedDeposit || "Error while fetching deposit address"} />
                      }
                      <ul className="disclaimer mt-3">
                        <label>Disclaimer</label>
                        <li>
                          • Minimum deposit of {minDeposit} {selectedCurrency}, deposit
                          below that cannot be recovered.
                        </li>
                        <li>
                          • Please deposit only {selectedCurrency} on this
                          address. If you deposit any other coin, it will be
                          lost forever.
                        </li>
                        <li>
                          • This is {selectedChain === 'RIK' ? 'RIK20' : selectedChain} deposit address type.
                          Transferring to an unsupported network could result in
                          loss of deposit.
                        </li>
                      </ul>
                    </div>
                  </>}

              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Withdrwal modal first  */}
      <div className="modal fade" id="Withdraw_modal" tabIndex="-1" aria-labelledby="Withdraw_modalLaebl" aria-hidden="true" >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header flex-column px-8">
              <h3 className="modal-title" id="placeBitLaebl">
                Withdraw Funds
              </h3>
              <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close">
                <i className="ri-close-fill"></i>
              </button>
            </div>
            <div className="modal-body px-8 py-5">
              <>
                {selectedCurrency !== "INR" &&
                  <>
                    <div className="btn-group btn_radio_group d-flex justify-content-center align-items-center m-auto " role="group" aria-label="Basic radio toggle button group">
                      {allChain ? allChain?.map((item, index) => {
                        return (
                          <button key={index} type="button" className={`btn btn-outline-primary ${selectedChain === item && "active"}`} htmlFor={item} onClick={() => { setSelectedchain(item) }}>
                            {item === 'RIK' ? 'RIK20' : item}
                          </button>)
                      }) : ''}
                    </div>

                    <div className="form-group mb-4">
                      <input type="text" name="wallet_Add" value={walletAddress} placeholder="Wallet Address" onChange={(e) => handleWalletAddress(e)} />
                      <small className="text-danger">{CheckWalletAddress && walletAddress ? 'Please enter valid Wallet Address' : ''}</small>
                    </div>
                  </>
                }
                <div className="form-group mb-4">
                  <input type="number" name="amount_val" value={withdrawAmount} placeholder="Amount" onChange={(e) => setWithdrawAmount(e.target.value)} />
                </div>
                <div className="field-box field-otp-box  mb-4">
                  <input type="number" name="wallet_Add" value={otp} placeholder="Enter OTP" onChange={(e) => setOtp(e.target.value)} onWheel={(e) => { e.target.blur() }} />
                  <button type="button" className="btn btn-sm btn-gradient" disabled={isButtonDisabled} onClick={() => handleGetOtp(email ? email : mobile, true)}>
                    <span> {isButtonDisabled ? `Resend OTP in ${timer} sec` : 'Get OTP'}</span>
                  </button>
                </div>
                <small className="d-block text-center mt-2">OTP will sent to {email ? email : mobile}</small>
                <ul className="disclaimer mt-3">
                  <label>Disclaimer</label>
                  <li>
                    • Minimum Withdrawal should be of {wihtdrawalDetails.minWithdrawal}
                  </li>
                  <li>
                    • Maximum Withdrawal should be of : {wihtdrawalDetails.maxWithdrawal}
                  </li>
                  <li>
                    • Withdrawal Fee will be: {wihtdrawalDetails.withdrawalFee}
                  </li>
                </ul>
                <div className="d-flex align-items-center justify-content-between mb-3">
                </div>
                <div className="form-group mb-4 d-flex justify-content-center align-items-center m-auto">
                  {selectedCurrency !== "INR" ? <button type="button" className="btn btn-success active" disabled={!withdrawAmount || !walletAddress || !otp || CheckWalletAddress} data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#Withdraw_modal2">
                    Withdraw
                  </button> :
                    <button type="button" className="btn btn-success active" disabled data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#Withdraw_modal2">
                      Withdraw
                    </button>}
                </div>
              </>
            </div>
          </div>
        </div>
      </div>

      {/* Withdrawal Modal */}
      <div className="modal fade" id="Withdraw_modal2" tabIndex="-1" aria-labelledby="Withdraw_modalLaebl" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header flex-column px-8">
              <h3 className="modal-title" id="placeBitLaebl">
                Confirm
              </h3>
              <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close" >
                <i className="ri-close-fill"></i>
              </button>
            </div>
            <div className="modal-body px-8 py-5">
              <>
                <div className="form-group mb-4">
                  <h4>
                    You are going to withdraw {selectedCurrency}{" "}
                    {withdrawAmount},
                    please confirm your withdraw by clicking 'Confirm' button
                    below.
                  </h4>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group mb-4">
                      <button type="button" className="btn btn-danger btn-small w-100 justify-content-center" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#Withdraw_modal2" >
                        <span>Cancels</span>
                      </button>
                    </div>
                  </div>

                  <div className="col">
                    <div className="form-group mb-4">
                      <button type="button" className="btn btn-success btn-small w-100 justify-content-center" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#Withdraw_modal2" onClick={() => { WithdrawalCurrency(currencyId, selectedChain, withdrawAmount, walletAddress, otp, email ? email : mobile) }}>
                        <span>Confirm</span>
                      </button>
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>

      {/* TradeHistory modal */}
      <div className="modal fade" id="trade_history" tabIndex="-1" aria-labelledby="transfer_history" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header flex-column px-8">
              <h3 className="modal-title" id="placeBitLaebl">
                Trade Details
              </h3>
              <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close">
                <i className="ri-close-fill"></i>
              </button>
            </div>
            <div className="modal-body px-8 py-5 body_history">
              <div className="tt_data">
                <div className="tt_item ">
                  <span className="tt_disable">Date &amp; Time</span>
                  <span className="tt_normal">
                    <b><Moment date={tradeHisDetails?.updatedAt} format='MMMM Do YYYY, h:mm:ss a' /> </b>
                  </span>
                </div>
                <div className="tt_item">
                  <span className="tt_disable">Currency</span>
                  <span className="tt_normal">
                    <b>{tradeHisDetails?.currency}</b>
                  </span>
                </div>
              </div>
              <div className="tt_item ">
                <span className="tt_disable">Side</span>
                <span className="tt_normal">
                  <b>{tradeHisDetails?.side}</b>
                </span>
              </div>

              <div className="tt_data">
                <div className="tt_item">
                  <span className="tt_disable">Transaction Type</span>
                  <span className="tt_normal">
                    <b>{tradeHisDetails?.transaction_type}</b>
                  </span>
                </div>
                <div className="tt_item ">
                  <span className="tt_disable">
                    Fee
                  </span>
                  <span className="tt_normal">
                    <b>{tradeHisDetails?.fee}</b>
                  </span>
                </div>

                <div className="tt_item ">
                  <span className="tt_disable">Fee Type</span>
                  <span className="tt_normal">
                    <b>{tradeHisDetails?.fee_type}</b>
                  </span>
                </div>
                <div className="tt_item">
                  <span className="tt_disable">Amount</span>
                  <span className="tt_normal">
                    <b>{tradeHisDetails?.amount}</b>
                  </span>
                </div>
                <div className="tt_item ">
                  <span className="tt_disable">Order Type</span>
                  <span className="tt_normal">
                    <b>{tradeHisDetails?.order_type}</b>
                  </span>
                </div>

                <div className="tt_item ">
                  <span className="tt_disable">Quantity</span>
                  <span className="tt_normal">
                    <b>{tradeHisDetails?.quantity}</b>
                  </span>
                </div>
                <div className="tt_item ">
                  <span className="tt_disable">Order Id</span>
                  <span className="tt_normal">
                    <b>{tradeHisDetails?.order_id}</b>
                  </span>
                </div>


                <div className="tt_item ">
                  <span className="tt_disable">Remarks</span>
                  <span className="tt_normal">
                    <b>fipnext Exchange</b>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Wallet History modal */}
      <div className="modal fade" id="wallet_history" tabIndex="-1" aria-labelledby="transfer_history" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header flex-column px-8">
              <h3 className="modal-title" id="placeBitLaebl">
                Transaction Details
              </h3>
              <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close">
                <i className="ri-close-fill"></i>
              </button>
            </div>
            <div className="modal-body px-8 py-5 body_history">
              <div className="tt_data">
                <div className="tt_item">
                  <span className="tt_disable">Date/Time</span>
                  <span className="tt_normal">
                    <b><Moment date={walletHisDetail?.updatedAt} format='MMMM Do YYYY, h:mm:ss A' /></b>
                  </span>
                </div>
              </div>
              <div className="tt_data">
                <div className="tt_item">
                  <span className="tt_disable">Coin</span>
                  <span className="tt_normal">
                    <b>{walletHisDetail?.short_name}</b>
                  </span>
                </div>
              </div>
              <div className="tt_item ">
                <span className="tt_disable">Status</span>
                <span className="tt_normal">
                  <b>{walletHisDetail?.status}</b>
                </span>
              </div>

              <div className="tt_data">
                <div className="tt_item">
                  <span className="tt_disable">Transaction Type</span>
                  <span className="tt_normal">
                    <b>{walletHisDetail?.transaction_type}</b>
                  </span>
                </div>
                <div className="tt_item ">
                  <span className="tt_disable">
                    Fee<small> (Incl.of all applicable taxes)</small>
                  </span>
                  <span className="tt_normal">
                    <b>{walletHisDetail?.fee}</b>
                  </span>
                </div>
                <div className="tt_item">
                  <span className="tt_disable">Amount</span>
                  <span className="tt_normal">
                    <b>{walletHisDetail?.amount}</b>
                  </span>
                </div>
                <div className="tt_item ">
                  <span className="tt_disable">From Address</span>
                  <span className="tt_normal">
                    <b>{walletHisDetail?.from_address}</b>
                  </span>
                </div>
                <div className="tt_item ">
                  <span className="tt_disable">To Address</span>
                  <span className="tt_normal">
                    <b>{walletHisDetail?.to_address}</b>
                  </span>
                </div>

                <div className="tt_item ">
                  <span className="tt_disable">Transaction Hash</span>
                  <span className="tt_normal">
                    <b>{walletHisDetail?.transaction_hash}</b>
                  </span>
                </div>
                <div className="tt_item ">
                  <span className="tt_disable">Date &amp; Time</span>
                  <span className="tt_normal">
                    <b>{moment(walletHisDetail?.updatedAt).format("MMMM Do YYYY, h:mm:ss a")} </b>
                  </span>
                </div>

                <div className="tt_item ">
                  <span className="tt_disable">Remarks</span>
                  <span className="tt_normal">
                    <b>fipnext Exchange</b>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default FundPage;
