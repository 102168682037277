import React, { useState, useEffect, useContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import { ApiConfig } from "../../Api_Module/Api_Config/ApiEndpoints";
import { SocketContext } from "../../UtilityComponent/SocketContext";
import { Link } from "react-router-dom";
import TypeIt from "typeit-react";
import Slider from "react-slick";
import "swiper/css";
import "swiper/css/pagination";
import AuthService from "../../Api_Module/Api_Services/AuthService";
import LoaderHelper from "../../UtilityComponent/Loading/LoaderHelper";

const LandingPage = () => {
  const [upperPairData, setUpperPairData] = useState([]);
  const { socket } = useContext(SocketContext);
  const [homedata, setHomedata] = useState([]);

  useEffect(() => {
    getHomeData()
  },[])
  const getHomeData = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.homeData().then((result) => {
      if (result?.success) {
        setHomedata(result?.data)
      }
    })
  };

  var settings = {
    centerMode: false,
    centerPadding: '20px',
    dots: true,
    infinite: false,
    autoplay: true,
    speed: 600,
    autoplaySpeed: 2000,
    arrows: false,
    slidesToShow: 6,
    slidesToScroll: 'auto',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerPadding: '3px',
          slidesToShow: 1,
          slidesToScroll: 'auto',
        },
      },
      {
        breakpoint: 1199,
        settings: {
          centerPadding: '20px',
          slidesToShow: 4,
          slidesToScroll: 'auto',
        },
      },
    ],
  };

  useEffect(() => {
    let interval;
    if (socket) {
      let payload = {
        'message': 'market',
      }
      socket.emit('message', payload);
      interval = setInterval(() => {
        let payload = {
          'message': 'market',
        }
        socket.emit('message', payload);
      }, 5000)

      socket.on('message', (data) => {
        const pairs = data?.pairs?.reverse();
        setUpperPairData(pairs?.slice(0, 10));

      });
    }
    return (() => {
      clearInterval(interval)
    })
  }, [socket]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <>

      <div className="home_section" id="home">

        <section className="hero-section hero-banner-style top-section-gap " id="section_1">
          <div className="container">
            <div className="row  align-items-center">
              <div className="col-12" >
                <h4 className="h_t_title mytheme-color">
                  <TypeIt
                    options={{
                      speed: 40,
                      waitUntilVisible: false,
                    }}
                  >
                    <span className="text-white text-underline me-1"> World‘s First Online </span>
                    & Ulitimate & Alternative, secured & one stop destination for high yield,
                    fixed income on your crypto investments
                  </TypeIt>
                </h4>
              </div>
              <div className=" col-lg-5">
                <div className="banner-content">
                  <h1 className="title">
                    <small className="d-block">The fastest and easiest way to</small>
                    <em className="text-gradient"> Buy, Sell & Trade</em>
                  </h1>

                  <p>Your gateway to the world of Crypto Trading</p>

                  <h6> SAFE <span className="mx-3"> | </span> SECURE <span className="mx-3"> | </span> CREATIVE </h6>

                  <div className="group-btn mt-6">
                    <a href="/trade/Landing_page" className="btn btn-gradient">
                      <span>Get Started</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className=" col-lg-7 ">
                <div className="hero_img" >
                  <div className="hero_ml" >
                    <div className="ml_card" >
                      <b>{homedata?.users}</b>
                      <span>Worldwide Users</span>
                    </div>
                    <div className="ml_card" >
                      <b>{homedata?.currency}</b>
                      <span>Listed crypto currrencies  </span>
                    </div>
                    <div className="ml_card" >
                      <b>{homedata?.days}</b>
                      <span>Running Days  </span>
                    </div>
                  </div>
                  {/* <img src="/images/hero-img_light.svg" className="img-fluid" alt="" /> */}
                  <img src="/images/home-logo.svg" className="img-fluid" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section> 

        <section className=" live_prices  ">
          <div className="container-fluid px-0">
            <Slider className="mb_slide" {...settings}>
              {upperPairData
                ? upperPairData?.map((item, index) => {
                  return (
                    <>
                    <div className="slidess" key={index}>
                      <a href={`/trade/${item?.base_currency}_${item?.quote_currency}`}>
                        <div className="main__card " >
                          <div className="main__icon"><img src={ApiConfig.baseUrl + item?.icon_path} alt="fipnext" className="img-fluid" /></div>
                          <div className="main__details">
                            <div className="main__line">
                              <div className="main__subtitle">{item?.base_currency}/{item?.quote_currency} </div>
                              <span className={`price_small badge main__negative ${item?.change >= 0 ? "badge-success" : "badge-danger"}`}  >
                                {item?.change?.toFixed(2)}
                              </span>
                            </div>
                            <div className="main__price  ">{item?.sell_price?.toFixed(2)}</div>
                            <div className="main__money">{item?.high?.toFixed(2)}</div>
                          </div>
                        </div>
                      </a>
                    </div>

                    </>
                    
                  );
                })
                : null}
            </Slider>
          </div>
        </section>

        <section className="highlight_sec">
          <div className="container">
            <div className="section-title section-title-center text-center">
              <h2 className="text-gradient"> Highlights  </h2>
            </div>
            <div className="card" >
              <div className="card-body" >
                <img src="/images/sl_arrow.svg" alt="" className="img-fluid sl_arrow" />
                <div className="row gx-5 align-items-center" >
                  <div className="col-lg-7" >
                    <div className="h-swiper" >
                      <Swiper
                        centeredSlides={true}
                        slidesPerView={3}
                        spaceBetween={1}
                        direction={'vertical'}
                        autoplay={{
                          delay: 1800,
                          disableOnInteraction: false,
                        }}
                        pagination={{
                          clickable: true,
                          dynamicBullets: true,
                        }}
                        modules={[Pagination, Autoplay]}
                        className="mySwiper"
                      >
                        <SwiperSlide>
                          <p>
                            Earn fixed ROI of 12% (1% per month) for your first investment
                          </p>
                        </SwiperSlide>
                        <SwiperSlide>
                          <p>
                            Invest as low as 1500 USDT & up to any amount with zero risk
                          </p>
                        </SwiperSlide>
                        <SwiperSlide>
                          <p>
                            No joining fee, No commissions
                          </p>
                        </SwiperSlide>
                        <SwiperSlide>
                          <p>
                            Easy account opening, online KYC, and e-agreement within minutes
                          </p>
                        </SwiperSlide>
                        <SwiperSlide>
                          <p>
                            All your investments will stay in your account all the time as we link your accounts to our mining pool to provide you with higher returns
                          </p>
                        </SwiperSlide>
                        <SwiperSlide>
                          <p>
                            Earn a 1% penalty on the investment for even a one-day delay of payments in any month
                          </p>
                        </SwiperSlide>
                        <SwiperSlide>
                          <p>
                            Automatic monthly payments to your bank accounts
                          </p>
                        </SwiperSlide>
                        <SwiperSlide>
                          <p>
                            No lock-in period & No hidden conditions
                          </p>
                        </SwiperSlide>
                        <SwiperSlide>
                          <p>
                            Can track your funds online & always through the dashboard, mobile app
                          </p>
                        </SwiperSlide>
                        <SwiperSlide>
                          <p>
                            Supported by FIPCOIN, FIP Trade Factory and Bridge Funding & Investment Limited which have a user base of 6000+ Investors and a presence in 5+ countries
                          </p>
                        </SwiperSlide>
                      </Swiper>
                    </div>
                  </div>
                  <div className="col-lg-5" >
                    <img src="images/hightlight_img.svg" alt="" className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>



        <section className=" market_update market_update_table    ">
          <div className="container">
            <div className="section-title section-title-center text-center">
              <h2 className="text-gradient">Market Update</h2>
              <p>
                Never miss a beat in the crypto world with our real-time market
                updates
              </p>
            </div>
          </div>
          <div className="container">
            <div className="card_table" >
              <div className="table-responsive  ">
                {upperPairData ?
                  <table className="table   ">
                    <thead>
                      <tr>
                        <th> Pair</th>
                        <th> Price</th>
                        <th> 24H Change</th>
                        {/* <th> 24H High</th>
                          <th> 24H Low</th> */}
                        <th> 24H Vol</th>
                        <th> 24H Turnover</th>
                        <th> Operation</th>
                      </tr>
                    </thead>
                    <tbody>
                      {upperPairData.map((item, index) => (
                        <tr key={index}>
                          <td>
                            {" "}

                            <div className="td_div">
                              <img alt=""
                                src={ApiConfig.appUrl + item?.icon_path}
                                className="img-fluid icon_img coinimg me-2 "
                              />
                              <div className="coin_name"><span> {item?.base_currency}/{item?.quote_currency} </span>	<br /> <small> {item?.base_currency}  </small></div>

                            </div>
                          </td>
                          <td>
                            <b>{item?.buy_price}</b>
                          </td>
                          <td
                            className={item?.change >= 0 ? "color-green text-success" : "color-red text-danger"}>
                            <b>{parseFloat(item?.change?.toFixed(5))} </b>
                          </td>
                          {/* <td>
                              <b>{item?.high}</b>
                            </td>
                            <td>
                              <b>{item?.low}</b>
                            </td> */}
                          <td>
                            <b>{parseFloat((item?.volume?.toFixed(5)))}</b>
                          </td>
                          <td>
                            <b>{parseFloat((item?.buy_price * item?.volume)?.toFixed(5))}</b>
                          </td>
                          <td className="text-right" >
                            <a href={`/trade/${item?.base_currency}_${item?.quote_currency}`} className="btn btn-theme btn-sm my-0">
                              Trade
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table> : null}
              </div>


              <div className="text-center pt-10">
                <a className="btn btn-light px-5" href="/MarketPage"> View All<i className="ms-2 ri-arrow-right-line"></i></a>
              </div>



            </div>
          </div>
        </section> 



      <section className="how_it_sec h_app_sec">
        <div className="container">
          <div className="row  align-items-end">
            <div className="  col-lg-5 ">
              <div className="section-title  text-start">
                <h2 style={{fontSize:"xx-large",textAlign:"center"}} className="mb-2">Maximise your crypto trading potential with our user-friendly & simple platform & mobile applications. </h2>
                {/* <p>Maximise your crypto trading potential with our user-friendly & simple platform & mobile applications.</p> */}
               <div className="d-flex justify-content-center"><a className="btn  btn-dark btn-xl" href="/trade/Landing"> Start Trade <i className="ms-3 ri-arrow-right-line pe-0"></i> </a></div>
              </div>
            </div>
            <div className="  col-lg-7 "><img src="images/mob_mockup_1.png" className="img-fluid" alt="" /></div>
          </div>
        </div>
      </section>



      <section className=" wactp_sec ">
        <div className="container">
          <div className="section-title section-title-center text-center">
            <h2>
              World Class Trading
              <span className="text-gradient"> Platform </span>
            </h2>
          </div>
          <div className="row justify-content-center g-3 mobile_row_scroll ">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 ">
              <div className="card fe_body h-100">
                <div className="card-body justify-content-start"><span className="sw_icon"><img src="/images/feature/1.svg" className="img-fluid" /> </span>
                  <h5>Exchange</h5>
                  <p className="mb-0 ">Join the Future of Finance<br/> Log in to Your fipnext exchange and Embark on Your Journey of Buying and Selling Crypto currencies Today!</p>
                </div>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 ">
              <div className="card fe_body h-100">
                <div className="card-body justify-content-start"><span className="sw_icon"><img src="/images/feature/2.svg" className="img-fluid" /> </span>
                  <h5>High Performance</h5>
                  <p className="mb-0">Seamless Trading<br/> Trade with Confidence and Easy, Supported by Cutting-Edge Software and Exceptional Service.</p>
                </div>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 ">
              <div className="card  fe_body h-100">
                <div className="card-body justify-content-start"><span className="sw_icon"><img src="/images/feature/3.svg" className="img-fluid" /> </span>
                  <h5>Super Fast KYC</h5>
                  <p className="mb-0">Lightning-Fast KYC<br/>  Experience Seamless Verification and User-Friendly KYC Processes. Get Trading in Seconds!</p>
                </div>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mt-3">
              <div className="card fe_body h-100">
                <div className="card-body justify-content-start"><span className="sw_icon"><img src="/images/feature/4.svg" className="img-fluid" /> </span>
                  <h5>Order Types</h5>
                  <p className="mb-0">With a diverse array of order types at your fingertips, tailored to your every trading need, 
                  experience the ultimate freedom in crypto asset transactions.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mt-3">
              <div className="card  fe_body h-100">
                <div className="card-body justify-content-start"><span className="sw_icon"><img src="/images/feature/5.svg" className="img-fluid" /> </span>
                  <h5>Minimal Interface</h5>
                  <p className="mb-0">Where Minimalism Meets Functionality.<br/>  Our platform boasts a sleek and minimal interface, designed for maximum user efficiency</p>
                </div>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mt-3">
              <div className="card fe_body h-100">
                <div className="card-body justify-content-start"><span className="sw_icon"><img src="/images/feature/6.svg" className="img-fluid" /> </span>
                  <h5>Safe and Secure</h5>
                  <p className="mb-0">We prioritize your security above all else.<br/>  Our platform employs top-notch security measures, guaranteeing the safety of your transactions.</p>
                </div>
              </div>
            </div>
          </div>   
        </div>
      </section>



      <section className=" dd_sec tbf_section ">
        <div className="container">
          <div className="section-title section-title-center text-center">
            <h2>
            How it works
            </h2>
          </div>
          <div className="dd_cards">
            <div className="row   ">
              <div className="col-md-4">
                <div className="wallet-block text-center hiw_card hiw_card_bar  ">
                  <div className="thumb d-flex justify-content-center"><img src="/images/how_1.svg" alt="" className="img-fluid" /></div><span>Step 1</span>
                  <h4 className="">Login &amp; Register  </h4>
                  <p>Enter your mobile no. or email address and create a strong password.</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="wallet-block text-center hiw_card hiw_card_bar  ">
                  <div className="thumb d-flex justify-content-center"><img src="/images/how_2.svg" alt="" className="img-fluid" /></div><span>Step 2</span>
                  <h4>Complete KYC  </h4>
                  <p>Complete KYC and two-factor authentication process (2FA). Wait for your account to be verified and approved .</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="wallet-block text-center hiw_card hiw_card_bar">
                  <div className="thumb d-flex justify-content-center"><img src="/images/how_3.svg" alt="" className="img-fluid" /></div><span>Step 3</span>
                  <h4>Start Trading  </h4>
                  <p> Once approved, login to your account and start trading. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>




      <div className="partner_community ">
        <div className="community_sec">
          <div className="container">
            <div className="card">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col-lg-7">
                    <div className="section-title mb-0 text-start pb-0 no-border">
                        <h1 className="">Join Our Community </h1>
                        <p className=" mb-0">fipnext is global. Join the conversation in any of our worldwide communities.</p>
                        <div className="d-flex  joc_social_row ">
                        <a rel="noreferrer" className="joc_social" target="_blank" href="https://www.facebook.com/fipnext" ><i className="ri-facebook-line  ri-xl"></i></a>
                        <a rel="noreferrer" className="joc_social" target="_blank" href="https://x.com/fipnext"><i className="ri-twitter-line  ri-xl"></i></a>
                        <a rel="noreferrer" className="joc_social" target="_blank" href="https://www.instagram.com/fipnext"><i className="ri-instagram-line  ri-xl"></i></a>
                        <a className="joc_social" target="_blank" href="https://linkedin.com/fipnext" rel="noreferrer"><i className="ri-linkedin-line ri-xl"></i></a>
                        <a className="joc_social" target="_blank" href="https://youtube.com/channel/fipnext" rel="noreferrer"><i className="ri-youtube-line ri-xl"></i></a>
                        </div>
                    </div>
                  </div>
                  <div className="col-lg-5"><img src="/images/joc_img_2.svg" alt="" className="img-fluid joc_img" /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
         

      </div>
    </>
  );
};

export default LandingPage;
