import { ApiConfig } from "../../Api_Module/Api_Config/ApiEndpoints";
import { makeApiRequest2, parseFullSymbol } from "./helpers";
const { io } = require("socket.io-client");

const channelToSubscription = new Map();
let socket = io(`${ApiConfig?.webSocketUrl}`, { transports: ['websocket'], upgrade: false, rejectUnauthorized: false, reconnectionAttempts: 5, debug: true });
var checkCurrPair;
var unsubscribe;
var interval;

export async function subscribeOnStream(
	symbolInfo,
	resolution,
	onRealtimeCallback,
	subscriberUID,
	onResetCacheNeededCallback,
	lastDailyBar,
) {
	const channelString = symbolInfo.name;
	const handler = {
		id: subscriberUID,
		callback: onRealtimeCallback,
	};
	const parsedSymbol = parseFullSymbol(symbolInfo?.name);
	const ApiData = await makeApiRequest2(parsedSymbol?.fromSymbol, parsedSymbol?.toSymbol);
	let CoinID = ApiData?.currency_ids;
	let historicalDataLength = ApiData?.count;
	let subscriptionItem = channelToSubscription.get(channelString);
	if (subscriptionItem) {
		subscriptionItem.handlers.push(handler);
		return;
	}
	subscriptionItem = {
		subscriberUID,
		resolution,
		lastDailyBar,
		handlers: [handler],
	};
	channelToSubscription.set(channelString, subscriptionItem);
	let payload = {
		'message': 'exchange',
		'base_currency_id': CoinID?.base_currency_id,
		'quote_currency_id': CoinID?.quote_currency_id,
		'cursor': historicalDataLength,
	}
	socket.emit('message', payload);
	checkCurrPair = parsedSymbol?.fromSymbol + '/' + parsedSymbol?.toSymbol
	if (!unsubscribe) {
		interval = setInterval(() => {
			socket.emit('message', payload);
		}, 5000);
		unsubscribe = checkCurrPair;
	} else if (unsubscribe !== checkCurrPair) {
		clearInterval(interval)
		interval = setInterval(() => {
			socket.emit('message', payload);
		}, 5000);
		unsubscribe = checkCurrPair;
	}

	socket.on('message', (data) => {
		let currPair = data?.pairs?.filter((item) => { return item?.base_currency === parsedSymbol?.fromSymbol && item?.quote_currency === parsedSymbol?.toSymbol })
		let tickerData;
		if (data?.ticker?.length === 0) {
			return;
		}
		tickerData = data?.ticker && data?.ticker[data?.ticker?.length - 1];
		const tradeTime = tickerData?.time;
		const volume = tickerData?.volume;
		const tradePrice =tickerData?.close;
		const channelString = symbolInfo?.name;
		const subscriptionItem = channelToSubscription.get(channelString);
		if (subscriptionItem === undefined) {
			return;
		}
		const lastDailyBar = subscriptionItem?.lastDailyBar;
		if (!lastDailyBar) {
			return
		}
	
		function getStartOfMinute(timestamp) {
			const date = new Date(timestamp);
			date.setSeconds(0, 0); 
			return date.getTime();
		}
	
		const lastBarTime = getStartOfMinute(lastDailyBar?.time);
			const currentTradeMinute = getStartOfMinute(tradeTime);
			let bar;
			if (currentTradeMinute > lastBarTime){
				bar = {
					time: tradeTime,
					open: lastDailyBar?.low,
					high: tradePrice,
					low: tradePrice,
					close: tradePrice,
					volume: volume,
				};
				subscriptionItem.lastDailyBar = bar
			} else {
				bar = {
					...lastDailyBar,
					high: Math.max(lastDailyBar?.high, tradePrice),
					low: Math.min(lastDailyBar?.low, tradePrice),
					close: tradePrice,
					volume: lastDailyBar?.volume + volume, 
				};
			}
		subscriptionItem.lastDailyBar = bar;
		onRealtimeCallback(bar)

	});
}

export function unsubscribeFromStream(subscriberUID) {

	for (const channelString of channelToSubscription.keys()) {
		const subscriptionItem = channelToSubscription.get(channelString);
		let removeInterval = subscriptionItem?.subscriberUID?.split('_')[0]
		if (checkCurrPair === removeInterval) {
			clearInterval(interval)
		}
		const handlerIndex = subscriptionItem.handlers.findIndex(handler => handler.id === subscriberUID);
		if (handlerIndex !== -1) {
			subscriptionItem.handlers.splice(handlerIndex, 1);
			if (subscriptionItem.handlers.length === 0) {

				channelToSubscription.delete(channelString);
				break;
			}
		}
	}
}
