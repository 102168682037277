import React, { useContext, useEffect, useRef, useState } from 'react';
import './index.css';
import { widget } from '../charting_library';
import Datafeed from './datafeed';
import { ProfileContext } from '../../Context';
import logo from './mini_icon.png';
import { deployedUrl } from '../../Api_Module/Api_Config/ApiEndpoints';

export default function TVChartContainer({ symbol }) {
	const { tradeTheme } = useContext(ProfileContext);
	const functCheckRef = useRef(true);
	const [tvWidget, setTvWidget] = useState();

	const getChart = (symbol) => {
		const activeClass = document.body.className;
		const FinalClass = activeClass;
		const widgetOptions = {
			symbol: `${symbol}`,
			load_last_chart: true,
			interval: '1',
			custom_css_url: 'css/style.css',
			fullscreen: false,
			timezone: 'Asia/Kolkata',
			container: 'TVChartContainer',
			datafeed: Datafeed,
			has_intraday: true,
			library_path: '/charting_library/',
			pricescale: 100000000,
			intraday_multipliers: ['1', '60'],
			height: '395px',
			hide_resolution_in_legend: true,
			logo: {
				image: logo,
				link: `${deployedUrl}trade/trade`
			},
			time_frames: [],
			time_scale: {
				min_bar_spacing: 20
			},
			disabled_features: ["use_localstorage_for_settings", "adaptive_logo", "border_around_the_chart", 'header_symbol_search', 'header_interval_dialog_button', 'header_compare', 'header_undo_redo', 'header_resolutions'],
			theme: FinalClass === 'theme-light' ? 'light' : 'dark',
			overrides: {
				"symbolInfo.priceFormatter": {
					precision: 4
				},
				"paneProperties.background": FinalClass === 'theme-light' ? '#ffffff' : "#1e1e1e",
				"paneProperties.backgroundType": "solid",
			},
			loading_screen: {
				backgroundColor: FinalClass === 'theme-light' ? '#ffffff' : "#1e1e1e",
			},
		};
		const widgetInstance = new widget(widgetOptions);
		setTvWidget(widgetInstance);
	};

	useEffect(() => {
		if (symbol.split('/')[0] !== 'undefined') {
			if (functCheckRef.current) {
				getChart(symbol);
			}
			functCheckRef.current = false;
		}
	}, [symbol]);


	useEffect(() => {
		getChart(symbol);
	}, [tradeTheme]);

	useEffect(() => {
		if (tvWidget && symbol.split('/')[0] !== 'undefined' ) {
			tvWidget.onChartReady(() => {
				const chart = tvWidget.chart();
				if (chart) {
					chart.setSymbol(symbol, () => null);
				}
			});
		}
	}, [symbol]);

	useEffect(() => {
		if (tvWidget) {
			tvWidget.onChartReady(() => {
				const chart = tvWidget.chart();
				if (chart) {
					tvWidget.headerReady().then(function () {
						const intervals = [
							{ value: '1', label: '1 Min' },
							{ value: '5', label: '5 Min' },
							{ value: '15', label: '15 Min' },
							{ value: '30', label: '30 Min' },
							{ value: '60', label: '1 Hour' },
							{ value: 'D', label: '1 Day' },
							{ value: 'W', label: '1 Week' },
							{ value: 'M', label: '1 Month' }
						];
						intervals.forEach(interval => {
							const button = tvWidget.createButton();
							button.classList.add('custom-interval-button')
							button.title = `Switch to ${interval.label}`;
							button.addEventListener('click', function () {
								tvWidget.chart().setResolution(interval.value);
							});
							button.textContent = interval.label;
						});
					});
				}
			});
		}
	}, [tvWidget]);



	return (
		<div id='TVChartContainer' />
	);
}
