import React from "react";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import LandingPage from "../Ui_Components/LandingPage";
import UserHeader from "../Ui_Components/Layout/UserHeader";
import LoginPage from "../Ui_Components/LoginPage";
import AuthHeader from "../Ui_Components/Layout/AuthHeader";
import SignupPage from "../Ui_Components/SignupPage";
import Footer from "../Ui_Components/Layout/Footer";
import AccountPage from "../Ui_Components/Layout/Sidebar";
import TradePage from "../Ui_Components/TradePage";
import MarketPage from "../Ui_Components/MarketPage";
import ComingSoonPage from "../UtilityComponent/ComingSoonPage";
import FundPage from "../Ui_Components/FundPage";
import KycPage from "../Ui_Components/KycPage";
import TermsOfUsePage from "../Ui_Components/Layout/FooterPages/TermsOfUsePage";
import Career from "../Ui_Components/Layout/FooterPages/CareerPage";
import Support from "../Ui_Components/Layout/FooterPages/Support";
import FAQ from "../Ui_Components/Layout/FooterPages/FAQ";
import AboutUs from "../Ui_Components/Layout/FooterPages/AboutUs";
import PrivacyPolicyPage from "../Ui_Components/Layout/FooterPages/PrivacyPolicyPage";
import ForgotPassPage from "../Ui_Components/ForgotPassPage";
import ReferralPage from "../Ui_Components/ReferralPage";
import ReferralList from "../Ui_Components/ReferralList";
import FAQSidebar from "../Ui_Components/Layout/FooterPages/FAQ/FAQSidebar";
import Announcements from "../Ui_Components/Layout/FooterPages/Announcements";
import Careerlist from "../Ui_Components/Layout/FooterPages/CareerPage/Careerlist";
import AnnouncementList from "../Ui_Components/Layout/FooterPages/Announcements/AnnouncementList";
import AnnouncementListDetails from "../Ui_Components/Layout/FooterPages/Announcements/AnnouncementListDetails";
import CoinApply from "../Ui_Components/Layout/FooterPages/CoinListing/CoinApply";
import BlogList from "../Ui_Components/Layout/FooterPages/Blog/BlogList";
import BlogDetails from "../Ui_Components/Layout/FooterPages/Blog/BlogDetails";
import CoinListings from "../Ui_Components/Layout/FooterPages/CoinListing";
import Earn from "../Ui_Components/Layout/FooterPages/Earn";
import JobDiscription from "../Ui_Components/Layout/FooterPages/CareerPage/JobDiscription";
import JobApply from "../Ui_Components/Layout/FooterPages/CareerPage/JobApply";
import RiskDisclosure from "../Ui_Components/Layout/FooterPages/RiskDisclosure";
import Ventures from "../Ui_Components/Layout/FooterPages/Ventures";
import VIPServices from "../Ui_Components/Layout/FooterPages/VIPServices";
import Download from "../Ui_Components/Layout/FooterPages/Download";
import Fees from "../Ui_Components/Layout/FooterPages/Fees";
import BuyCrypto from "../Ui_Components/Layout/FooterPages/BuyCrypto";
import LawEnforcement from "../Ui_Components/Layout/FooterPages/LawEnforcementGuide";
import HowToBuy from "../Ui_Components/Layout/FooterPages/HowToBuy";
import LawEnforcementReq from "../Ui_Components/Layout/FooterPages/LawEnforcementReq";
import CryptoDetails from "../Ui_Components/Layout/FooterPages/CryptoInformation/CryptoDetails";
import CryptoInformation from "../Ui_Components/Layout/FooterPages/CryptoInformation";
import Affiliate from "../Ui_Components/Layout/FooterPages/Affiliate";
import SettingsPage from "../Ui_Components/SettingsPage";
import TwofactorPage from "../Ui_Components/TwofactorPage";
import CurrencyPrefrence from "../Ui_Components/CurrencyPrefrence";
import ActivitylogPage from "../Ui_Components/ActivitylogPage";
import ErrorPage from "../UtilityComponent/404Page";
import BuySell from "../Ui_Components/QuickBuySell";
import Staking from "../Ui_Components/Staking";
import NotificationPage from "../Ui_Components/NotificationPage";
import SecurityPage from "../Ui_Components/SecurityPage";
import MobileChart from "../Ui_Components/MobileChart";
import BankAccount from "../Ui_Components/BankAccount";
const Routing = () => {
  return (
    <Router>
      {/*   ********* Component AlertBox **********  */}
      <ToastContainer position="top-right" autoClose={1000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss={false} draggable pauseOnHover limit={3} theme="light" />

      <HeaderComponent />
      <Routes >
        {/* ********** Common Routing ********** */}

        {/* <Route exact path="/*" element={<MaitancePage />}></Route> */}
        <Route exact path="/" element={<LandingPage />}></Route>
        <Route exact path="/*" element={<ErrorPage />}></Route>
        <Route exact path="/comingsoon" element={<ComingSoonPage />}></Route>
        <Route exact path="/trade/:pairs" element={<TradePage />}></Route>
        <Route exact path="/MarketPage" element={<MarketPage />}></Route>
        <Route exact path="/Career" element={<Career />}></Route>
        <Route exact path="/AboutUs" element={<AboutUs />}></Route>
        <Route exact path="/FAQ" element={<FAQ />}></Route>
        <Route exact path="/BuySell" element={<BuySell />}></Route>
        <Route exact path="/RiskDisclosure" element={<RiskDisclosure />}></Route>
        <Route exact path="/FIP-InvestVentures" element={<Ventures />}></Route>
        <Route exact path="/LawEnforcementGuide" element={<LawEnforcement />}></Route>
        <Route exact path="/LawEnforcementReq" element={<LawEnforcementReq />}></Route>
        <Route exact path="/VIPServices" element={<VIPServices />}></Route>
        <Route exact path="/affiliates" element={<Affiliate />}></Route>
        <Route exact path="/CryptoDetails" element={<CryptoDetails />}></Route>
        <Route exact path="/CryptoInformation" element={<CryptoInformation />}></Route>
        <Route exact path="/DownloadFIP-Invest" element={<Download />}></Route>
        <Route exact path="/BuyCrypto" element={<BuyCrypto />}></Route>
        <Route exact path="/Fees" element={<Fees />}></Route>
        <Route exact path="/how_to_buy" element={<HowToBuy />}></Route>
        <Route exact path="/PrivacyPolicyPage" element={<PrivacyPolicyPage />}></Route>
        <Route exact path="/mobPrivacyPolicyPage" element={<PrivacyPolicyPage />}></Route>
        <Route exact path="/Support" element={<Support />}></Route>
        <Route exact path="/mobSupport" element={<Support />}></Route>
        <Route exact path="/ReferralPage" element={<ReferralPage />}></Route>
        <Route exact path="/Earn" element={<Earn />}></Route>
        <Route exact path="/TermsOfUsePage" element={<TermsOfUsePage />}></Route>
        <Route exact path="/FAQSidebar" element={<FAQSidebar />}></Route>
        <Route exact path="/announcements" element={<Announcements />}></Route>
        <Route exact path="/careerlist" element={<Careerlist />}></Route>
        <Route exact path="/announcementlist" element={<AnnouncementList />}></Route>
        <Route exact path="/announcementlistdetails" element={<AnnouncementListDetails />}></Route>
        <Route exact path="/listingcoin" element={<CoinListings />}></Route>
        <Route exact path="/coinapply" element={<CoinApply />}></Route>
        <Route exact path="/bloglist" element={<BlogList />}></Route>
        <Route exact path="/blogdetails" element={<BlogDetails />}></Route>
        <Route exact path="/job_discription" element={<JobDiscription />}></Route>
        <Route exact path="/job_apply" element={<JobApply />}></Route>
        <Route exact path="/chart/:pairs" element={<MobileChart />}></Route>

        {/* ********** Before Login Routes ********** */}
        <Route exact path="/login" element={<NotProtectedRoutes> <LoginPage /> </NotProtectedRoutes>}></Route>
        <Route exact path="/ForgotPassPage" element={<NotProtectedRoutes><ForgotPassPage /> </NotProtectedRoutes>}></Route>
        <Route exact path="/signup" element={<NotProtectedRoutes><SignupPage /> </NotProtectedRoutes>}></Route>

        {/* ********** Protected Routes (After Login Routes)********** */}
        <Route exact path="/FundPage" element={<ProtectedRoutes><FundPage /> </ProtectedRoutes>}></Route>
        <Route exact path="/Staking" element={<ProtectedRoutes><Staking /> </ProtectedRoutes>}></Route>
        <Route exact path="/ReferralList" element={<ProtectedRoutes><ReferralList /> </ProtectedRoutes>}></Route>
        <Route exact path="/KycPage" element={<ProtectedRoutes><KycPage /> </ProtectedRoutes>}></Route>
        <Route exact path="/Profile" element={<ProtectedRoutes> <AccountPage /></ProtectedRoutes>}>
          <Route exact path="Settings" element={<SettingsPage />}></Route>
          <Route exact path="CurrencyPrefrence" element={<CurrencyPrefrence />}></Route>
          <Route exact path="Twofactor" element={<TwofactorPage />}></Route>
          <Route exact path="Activitylog" element={<ActivitylogPage />}></Route>
          <Route exact path="Notifications" element={<NotificationPage />}></Route>
          <Route exact path="BankAccount" element={<BankAccount />}></Route>
          <Route exact path="SecurityPage" element={<SecurityPage />}></Route>
        </Route>

      </Routes >
      <FooterWithoutTrade />
    </Router>
  );
};
function FooterWithoutTrade() {
  const location = useLocation();
  const isTradePage = location?.pathname?.includes('/trade');
  const isMobPrivacyPolicyPage = location?.pathname?.includes('/mobPrivacyPolicyPage');
  const isMobSupport = location?.pathname?.includes('/mobSupport');
  const isMobileChart = location?.pathname?.includes('/chart');
  return !isTradePage && !isMobPrivacyPolicyPage && !isMobSupport && !isMobileChart ? <Footer /> : null;
}


function ProtectedRoutes({ children }) {
  const AuthToken = sessionStorage.getItem("FIP-Invest_AUTH_TOKEN");
  return AuthToken ? children : <LoginPage />

};

function NotProtectedRoutes({ children }) {
  const AuthToken = sessionStorage.getItem("FIP-Invest_AUTH_TOKEN");
  return !AuthToken ? children : <AccountPage />
    ;
};

function HeaderComponent() {
  const location = useLocation();
  const AuthToken = sessionStorage.getItem("FIP-Invest_AUTH_TOKEN");
  const isMobSupport = location?.pathname?.includes('/mobSupport');
  const isMobPrivacyPolicyPage = location?.pathname?.includes('/mobPrivacyPolicyPage');
  const isMobileChart = location?.pathname?.includes('/chart');
  return (isMobPrivacyPolicyPage || isMobSupport || isMobileChart) ? null : !AuthToken ? <UserHeader /> : <AuthHeader />
    ;
};

export default Routing;
