import React, { useEffect, useState } from "react";
import AuthService from "../../../../Api_Module/Api_Services/AuthService";
import { Link } from "react-router-dom";
import { deployedUrl } from "../../../../Api_Module/Api_Config/ApiEndpoints";
const Earn = () => {
    const [totalReferrals, setTotalReferrals] = useState("");
    const [totalRewards, setTotalRewards] = useState("");
    const [referralLink, setReferralLink] = useState("");
    const [referralAmount, setReferralAmount] = useState();

    useEffect(() => {
        // handleReferralAmount();
        // handleReferralLink();
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    const handleReferralAmount = async () => {
        await AuthService.totalrefercount().then(async (result) => {
            if (result?.success) {
                setTotalReferrals(result?.data?.total_referred);
            }
        });
    };

    const handleReferralLink = async () => {
        await AuthService.referralLink().then(async (result) => {
            if (result?.success) {
                setReferralLink(result?.data?.user_code);
            }
        });
    };


    const link = `${deployedUrl}signup?reffcode=` + referralLink;
    const msg =
        "Get 20 fipnextworth 12$ on fipnext Sign Up Now | Valid for the first 100000 Sign Ups. Refer your friends to earn 10 fipnextworth 6$ on every signup & 50% of the commission fee generated by your invites.";

    const copyText = `Learn, Trade & Earn in Crypto, First ever exchange that offer’s you a platform to learn and earn Crypto.
                    
fipnext is coming up with features like : Trade farming, Trading rewards, Referral rewards (applicable to first 100000 sign-ups only).
                        
fipnext EXCHANGE WILL BE LIVE AFTER 100000 SIGN-UPS.
                        
Hurry up and don’t miss the opportunity to earn free Crypto, It's your time to be a part of World’s leading Crypto Learning, Trading & Earning platform.
                        
Sign-up now
${deployedUrl}signup?reffcode=${referralLink}`;

    return (
        <>


            <div className="about_page clist refer_page_main" >
                <section className="about_top referal_bg" >
                    <div className="container" >
                        <div className="row g-md-5 align-items-center" >
                            <div className="col-lg-6" >
                                <h2 className="mytheme-color" >
                                    Refer and Earn crypto <br /> <span className="" >  with fipnext</span>
                                </h2>
                                <h4>Refer and Earn <b className="mytheme-color">5%</b> on <b className="mytheme-color">FIPCOINs</b> purchased by your investors</h4>
                                <Link to='/signup' className="btn btn-gradient btn-xl mt-3" > <span>Sign Up Now</span> </Link>
                            </div>
                            <div className="col-lg-6" >
                                <img alt="" src="/images/refer_img.svg" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </section>


                <div className="container  my-5 py-5" >
                    <div className=" wallet-block" >
                        <div className="card-body" >
                            <section className="hurry_banner">
                                <div className="container">
                                    <h3 className=" ">Hurry! This limited-time opportunity is available for the first <strong>1000</strong> purchases</h3>
                                </div>
                            </section>




                            <section className=" hiw_sec pb-5">
                                <div className="container">
                                    <div className="section-title section-title-center text-center">
                                        <h2 className="text-gradient"> How Referral Works </h2>
                                        <p> Refer & Earn FIPCOIN in just 3 simple steps  </p>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-6">
                                            <div className="wallet-block text-center hiw_card hiw_card_bar">
                                                <div className="thumb m-auto">
                                                    <img src="/images/hiw_1.png" alt="nft wallet" />
                                                </div>
                                                <span className="text-white">Step 1</span>
                                                <h4 className="title text-gradient text-white">Sign-Up </h4>
                                                {/* <p> Earn 20 FIPCOIN: As soon as you sign up on
                                                    fipnext, you'll receive 20 FIPCOIN(FIPCOIN
                                                    tokens) as a warm welcome. </p> */}
                                            </div>
                                        </div>
                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-6">
                                            <div className="wallet-block text-center hiw_card hiw_card_bar">
                                                <div className="thumb m-auto">
                                                    <img src="/images/step_2.png" alt="nft wallet" />
                                                </div>
                                                <span className="text-white">Step 2</span>
                                                <h4 className="title text-gradient  text-white"> Get your Referral Link  </h4>
                                                {/* <p>
                                                    Once you sing up your referral link is
                                                    generated under refer and earn tab, you
                                                    can copy your referral link and share is
                                                    with your friends to earn more FIPCOIN

                                                </p> */}
                                            </div>
                                        </div>
                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-6">
                                            <div className="wallet-block text-center hiw_card">
                                                <div className="thumb m-auto">
                                                    <img src="/images/step_3.png" alt="nft wallet" />
                                                </div>
                                                <span className="text-white">Step 3</span>
                                                <h4 className="title text-gradient text-white"> Earn Referral Rewards </h4>
                                                {/* <p>Earn 10 FIPCOIN: Every time you refer a friend to FIPCOIN. </p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center">
                                <h5 className="text-center mb-5">Once your investor sign up with your given link, You will be able to see their details under your referral list, once they buy the FIPCOINs, <br/>you will get your referral income automatically to your USDT wallet within 24 hours.</h5>
                                    <Link to="/login" className="btn btn-xl btn-gradient mt-5"  >
                                        <span>Log In</span>
                                    </Link>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>



                {/* <div className="container">
                    <div className="newsletter-content refering_sec">
                        <h2>Start Referring Start Earning!  </h2>
                        <Link to="/login" className="btn btn-xl btn-gradient "  >
                            <span>Log In</span>
                        </Link>
                    </div>
                </div> */}









            </div>
        </>
    )
}

export default Earn
