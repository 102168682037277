import { ProfileProvider } from "./Context";
import Routing from "./Routing";
import Loading from "./UtilityComponent/Loading/index";
import LoaderHelper from "./UtilityComponent/Loading/LoaderHelper";
import SocketContextProvider from "./UtilityComponent/SocketContext";
function App() {

  // *********Language Widget ************//
  // useEffect(() => {
  //   googleTranslate()
  // }, []);

  return (
    <>
      <ProfileProvider>
        <SocketContextProvider>
          <Routing />
          <Loading ref={ref => LoaderHelper.setLoader(ref)} />
        </SocketContextProvider>
      </ProfileProvider>
    </>
  );
}

export default App;
