import * as go from 'gojs';
import { ReactDiagram } from 'gojs-react';

const Tree = ({ treeData }) => {
    const nameProperty = 'name';
    const genderProperty = 'gender';
    const statusProperty = 'status';
    const countProperty = 'count';
    const codeProperty = 'user_code';

    const theme = {
        colors: {
            femaleBadgeBackground: '#FFCBEA',
            maleBadgeBackground: '#A2DAFF',
            femaleBadgeText: '#7A005E',
            maleBadgeText: '#001C76',
            kingQueenBorder: '#FEBA00',
            princePrincessBorder: '#679DDA',
            civilianBorder: '#58ADA7',
            personText: '#383838',
            personNodeBackground: '#FFFFFF',
            selectionStroke: '#485670',
            counterBackground: '#485670',
            counterBorder: '#FFFFFF',
            counterText: '#FFFFFF',
            link: '#686E76'
        },
        fonts: {
            badgeFont: 'bold 12px Poppins',
            birthDeathFont: '14px Poppins',
            nameFont: '500 18px Poppins',
            counterFont: '14px Poppins',
            codeFont: '8px Poppins'
        }
    };

    // toggle highlight on mouse enter/leave
    // this sample also uses highlight for selection, so only unhighlight if unselected
    const onMouseEnterPart = (e, part) => part.isHighlighted = true;
    const onMouseLeavePart = (e, part) => { if (!part.isSelected) part.isHighlighted = false; }
    const onSelectionChange = (part) => { part.isHighlighted = part.isSelected; }

    const STROKE_WIDTH = 3;
    const ADORNMENT_STROKE_WIDTH = STROKE_WIDTH + 1;
    const CORNER_ROUNDNESS = 12;
    const IMAGE_TOP_MARGIN = 20;
    const MAIN_SHAPE_NAME = 'mainShape';
    const IMAGE_DIAMETER = 40;

    const getStrokeForStatus = (status) => {
        switch (status) {
            case 'king':
            case 'queen':
                return theme.colors.kingQueenBorder;
            case 'prince':
            case 'princess':
                return theme.colors.princePrincessBorder;
            case 'civilian':
            default:
                return theme.colors.civilianBorder;
        }
    };

    function strokeStyle(shape) {
        return shape
            .set({
                fill: theme.colors.personNodeBackground,
                strokeWidth: STROKE_WIDTH
            })
            .bind('stroke', statusProperty, status => getStrokeForStatus(status))
            .bindObject('stroke', 'isHighlighted', (isHighlighted, obj) =>
                isHighlighted
                    ? theme.colors.selectionStroke
                    : getStrokeForStatus(obj.part.data.status))
    }

    const genderToText = (gender) => (gender === 'M' ? 'MALE' : 'FEMALE');

    const genderToTextColor = (gender) =>
        gender === 'M' ? theme.colors.maleBadgeText : theme.colors.femaleBadgeText;

    const genderToFillColor = (gender) =>
        gender === 'M'
            ? theme.colors.maleBadgeBackground
            : theme.colors.femaleBadgeBackground;

    const personBadge = () =>
        new go.Panel('Auto', {
            alignmentFocus: go.Spot.TopRight,
            alignment: new go.Spot(1, 0, -25, STROKE_WIDTH - 0.5)
        })
            .add(
                new go.Shape({
                    figure: 'RoundedRectangle',
                    parameter1: CORNER_ROUNDNESS,
                    parameter2: 4 | 8, // round only the bottom
                    desiredSize: new go.Size(NaN, 22.5),
                    stroke: null
                })
                    .bind('fill', genderProperty, genderToFillColor),
                new go.TextBlock({
                    font: theme.fonts.badgeFont
                })
                    .bind('stroke', genderProperty, genderToTextColor)
                    .bind('text', genderProperty, genderToText)
            )

    const personBirthDeathTextBlock = () =>
        new go.TextBlock({
            stroke: theme.colors.personText,
            font: theme.fonts.birthDeathFont,
            alignmentFocus: go.Spot.Top,
            alignment: new go.Spot(0.5, 1, 0, -35)
        })
            .bind('text', '', ({ born, death }) => {
                if (!born) return '';
                return `${born} - ${death ?? ''}`;
            })

    // Panel to display the number of children a node has
    const personCounter = () =>
        new go.Panel('Auto', {
            visible: false,
            alignmentFocus: go.Spot.Center,
            alignment: go.Spot.Bottom
        })
            .bindObject('visible', '', (obj) => obj.findLinksOutOf().count > 0)
            .add(
                new go.Shape('Circle', {
                    desiredSize: new go.Size(29, 29),
                    strokeWidth: STROKE_WIDTH,
                    stroke: theme.colors.counterBorder,
                    fill: theme.colors.counterBackground
                }),
                new go.TextBlock({
                    alignment: new go.Spot(0.5, 0.5, 0, 1),
                    stroke: theme.colors.counterText,
                    font: theme.fonts.counterFont,
                    textAlign: 'center'
                })
                    .bindObject('text', '', (obj) => obj.findNodesOutOf().count)
            )

    function pictureStyle(pic) {
        return pic
            .bind('source', '', ({ status, gender }) => {
                switch (status) {
                    case 'king':
                    case 'queen':
                        return './images/king.svg';
                    case 'prince':
                    case 'princess':
                        return './images/prince.svg';
                    case 'civilian':
                        return gender === 'M'
                            ? './images/male-civilian.svg'
                            : './images/female-civilian.svg';
                    default:
                        return './images/male-civilian.svg';
                }
            })
            // The SVG files are different sizes, so this keeps their aspect ratio reasonable
            .bind('desiredSize', 'status', status => {
                switch (status) {
                    case 'king':
                    case 'queen':
                        return new go.Size(30, 20)
                    case 'prince':
                    case 'princess':
                        return new go.Size(28, 20)
                    case 'civilian':
                    default:
                        return new go.Size(24, 24)
                }
            });
    }

    const personImage = () =>
        new go.Panel('Spot', {
            alignmentFocus: go.Spot.Top,
            alignment: new go.Spot(0, 0, STROKE_WIDTH / 2, IMAGE_TOP_MARGIN)
        })
            .add(
                new go.Shape({
                    figure: 'Circle',
                    desiredSize: new go.Size(IMAGE_DIAMETER, IMAGE_DIAMETER)
                })
                    .apply(strokeStyle),
                new go.Picture({ scale: 0.9 })
                    .apply(pictureStyle)
            );

    const personMainShape = () =>
        new go.Shape({
            figure: 'Circle',
            width: 30,
            // desiredSize: new go.Size(215, 110),
            portId: '',
            parameter1: CORNER_ROUNDNESS,
            toolTip: go.GraphObject.build('ToolTip')
                .add(new go.Panel(go.Panel.Table, { margin: 0.5, defaultRowSeparatorStrokeWidth: 0.5 })
                    .add(new go.Panel(go.Panel.Table, {
                        background: "white",
                        column: 0,
                        alignment: go.Spot.Left,
                        stretch: go.Stretch.Vertical,
                        defaultAlignment: go.Spot.Left
                    })
                        .add(
                            new go.TextBlock({ editable: true, row:0, minSize: new go.Size(10, 14) })
                                .bindTwoWay('text', 'name')
                                .theme('stroke', 'text')
                                .theme('font', 'name')
                        ).add(new go.TextBlock({ row: 1, editable: true, minSize: new go.Size(10, 14) })
                            .bindTwoWay('text', 'user_code')
                            .theme('stroke', 'subtext')
                            .theme('font', 'normal')
                        ))
                )
        })
            .apply(strokeStyle);


    const personNameTextBlock = () =>
        new go.Panel('Auto', {
            alignmentFocus: go.Spot.Bottom,
            alignment: new go.Spot(0.5, 0, 0, 45)
        })
            .add(
                new go.Shape({
                    figure: 'RoundedRectangle',
                    parameter1: CORNER_ROUNDNESS,
                    desiredSize: new go.Size(50, 15),
                    stroke: null
                })
                    .bind('fill', codeProperty, genderToFillColor),
                new go.TextBlock({
                    font: theme.fonts.codeFont,
                })
                    //   .bind('stroke', codeProperty)
                    .bind('text', codeProperty)
            )
    //   new go.TextBlock({
    //     stroke: theme.colors.personText,
    //     font: theme.fonts.codeFont,
    //     background:'white',
    //     formatting: new go.TextFormat(),
    //     desiredSize: new go.Size(60, 15),
    //     // overflow: go.TextOverflow.,
    //     textAlign: 'center',
    //     verticalAlignment: go.Spot.Center,
    //     alignmentFocus: go.Spot.Top,
    //     margin : new go.Margin(0,0,20,0),
    //     alignment: new go.Spot(0.5, 0, 0, 35)
    //   })
    //     .bind('text', codeProperty)


    const createNodeTemplate = () =>
        new go.Node('Spot', {
            selectionAdorned: false,
            mouseEnter: onMouseEnterPart,
            mouseLeave: onMouseLeavePart,
            selectionChanged: onSelectionChange
        })
            .add(
                new go.Panel('Spot')
                    .add(
                        personMainShape(),
                        // personNameTextBlock(),
                        // personBirthDeathTextBlock()
                    ).add(personNameTextBlock()),
                //   personImage(),
                //   personBadge(),
                //   personCounter()
            )

    const createLinkTemplate = () =>
        new go.Link({
            selectionAdorned: false,
            routing: go.Routing.Orthogonal,
            layerName: 'Background',
            mouseEnter: onMouseEnterPart,
            mouseLeave: onMouseLeavePart
        })
            .add(
                new go.Shape({
                    stroke: theme.colors.link,
                    strokeWidth: 1
                })
                    .bindObject('stroke', 'isHighlighted', (isHighlighted) =>
                        isHighlighted ? theme.colors.selectionStroke : theme.colors.link
                    )
                    .bindObject('stroke', 'isSelected', (selected) =>
                        selected ? theme.colors.selectionStroke : theme.colors.link
                    )
                    .bindObject('strokeWidth', 'isSelected', (selected) => selected ? 2 : 1)
            );
    const initDiagram = () => {
        const diagram = new go.Diagram({
            initialAutoScale: go.AutoScale.Uniform,
            layout: new go.TreeLayout({
                angle: 90,
                nodeSpacing: 20,
                layerSpacing: 30,
                // layerStyle: go.TreeLayout.uni,

                // For compaction, make the last parents place their children in a bus
                // treeStyle: go.TreeStyle.LastParents,
                // alternateAngle: 90,
                // alternateLayerSpacing: 35,
                // alternateAlignment: go.TreeAlignment.BottomRightBus,
                // alternateNodeSpacing: 20
            }),
            'toolManager.hoverDelay': 100,
            linkTemplate: createLinkTemplate(),
            model: new go.TreeModel({ nodeKeyProperty: 'key' })
        });

        diagram.nodeTemplate = createNodeTemplate();
        const nodes = treeData;
        diagram.model.addNodeDataCollection(nodes);

        // Initially center on root:
        diagram.addDiagramListener('InitialLayoutCompleted', () => {
            const root = diagram.findNodeForKey('0');
            if (!root) return;
            diagram.scale = 0.6;
            diagram.scrollToRect(root.actualBounds);
        });

        // Setup zoom to fit button
        // document.getElementById('zoomToFit').addEventListener('click', () => diagram.commandHandler.zoomToFit());

        // document.getElementById('centerRoot').addEventListener('click', () => {
        //     diagram.scale = 1;
        //     diagram.commandHandler.scrollToPart(diagram.findNodeForKey('King George V'));
        // });
        return diagram;
    };
    return (
        treeData.length != 0 &&
        <ReactDiagram initDiagram={initDiagram} style={{ width: "100%", height: '80vh' }}></ReactDiagram>

    );
}
export default Tree;